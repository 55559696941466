import * as React from 'react';
import {Main} from "../components/Main";
import {Box} from "../components/sharedComponents/Box";
import Services from "../utilities/Services";
import Lang from "../utilities/Language";
import {kraj} from "../utilities/Utilities";

interface MatchParams {
    path: string;
}

export interface RouteComponentProps<MatchParams> {
    match: any;
    history: any;
}

interface Props extends RouteComponentProps<MatchParams> {
}

export class UsunKonto extends React.Component<Props>{

    state = {
        token: null,
        email: null,
        odpowiedz: false,
        czyPoprawnie: false,
        message: 'Błąd aktywacji !'
    };

    componentDidMount() {

        let {match} = this.props;

        if (match)
            if (match.params)
                if (match.params.token)
                {
                    this.setState({token: match.params.token, email: match.params.email});
                    this.usunKonto(match.params.token, match.params.email);
                }


    }

    usunKonto = (token: string, email: string) =>{
        // alert(token + email)
        Services.ConfirmUsunKontoKlienta(token, email)
            .then((response) => {
                // if (response.data === 'OK') {

                    //this.setState({onRegister: true, registerLoading: false})
                    console.log("Aktywacja ok");
                    this.setState({odpowiedz: true, czyPoprawnie: true});

                // } else {
                //     this.setState({odpowiedz: true, czyPoprawnie: false, message: 'Wystąpił błąd lub konto zostało już aktywowane.'});
                // }
            }).catch((error) => {
            //this.setState({registerLoading: false})
            //alert(error.response.data.message);
            this.setState({odpowiedz: true, czyPoprawnie: false, message: error.response.data.message});
            console.log('Aktywacja error');
        })
    }

    render()
    {
        let {odpowiedz, czyPoprawnie, message} = this.state;

        return(<Main active={'LOGIN'}>
                <Box>
                    {(odpowiedz) ?
                        <>
                            {(czyPoprawnie)?
                                <>
                                    <h3 style={{color: 'green'}}><i className="fa fa-check" style={{color: 'green'}}/> {Lang[kraj].kontoUsunietePomyslnie}!</h3>
                                    {/*<p>Możesz zacząć korzystać ze swojej e-karty</p>*/}
                                </>
                            :
                                <h3>{message}</h3>
                            }
                        </>
                        :
                        <>
                            <h4><i className="fa fa-spinner fa-spin"/> {Lang[kraj].proszeCzekacUsuwanieKonta}...</h4>
                        </>
                    }
                </Box>
            </Main>
        );
    }
}

export default UsunKonto;


