import * as React from 'react';

interface Props {
    children: any,
    style?: any
}

export const Box = (props: Props) => {

    return <>
        {/*<div className={'container'}>*/}
        <div id={'main-container'} style={props.style}>
            {props.children}
        </div>
        {/*</div>*/}
    </>

}

Box.defaultProps = {}

