import * as React from 'react';
import {Header} from "./sharedComponents/Header";
import {Footer} from "./sharedComponents/Footer";

interface Props {
    active: string,
    children: any
}

export class Main extends React.Component<Props> {

    render() {
        return( <>
            <Header active={this.props.active}/>

            <div className={'container'}>
                <div className={'containerm'}>
                    {this.props.children}
                </div>
            </div>

            <Footer/>
        </>
        );
    }

}

export default Main

