import * as React from 'react';
import Services from "../../utilities/Services";
import axios from "axios";
import {getCurrentUser, isEmpty, kraj, logOut} from "../../utilities/Utilities";
import {NotificationContainer, NotificationManager} from "react-notifications";
import InputMask from "react-input-mask";
import {Modal, Button, Form, CloseButton} from "react-bootstrap";
import Lang from "../../utilities/Language";
import Language from "../../utilities/Language";
//import {Simulate} from "react-dom/test-utils";
//import error = Simulate.error;

interface Props {
}

interface State {

    powNoweHaslo: any,
    noweHaslo: any,
    stareHaslo: any,
    oddzialy: any,
    user: any,
    isEdit: boolean,
    loading: boolean,
    zapisywanie: boolean,
    czyEdytowac: boolean,
    czyFirma: boolean,
    showUsunKonto: boolean,
    haslo: string,
    usuwanieKonta: boolean
}

export class Account extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            user: {
                'login': '',
                'haslo': '',
                'nrIdent': '',
                'firma': '',
                'firma2': '',
                'miasto': '',
                'kodPocztowy': '',
                'kraj': 'PL'
            },
            oddzialy: [],
            stareHaslo: '',
            noweHaslo: '',
            powNoweHaslo: '',
            isEdit: false,
            loading: false,
            zapisywanie: false,
            czyEdytowac: false,
            czyFirma: false,
            showUsunKonto: false,
            haslo: '',
            usuwanieKonta: false
        }

    }

    componentDidMount() {
        this.pobierzKlient();

        // this.setState({
        //     username: getCurrentUser().data.username,
        //     adres: getCurrentUser().data.adres,
        //     adres2: getCurrentUser().data.adres2
        // });

        //alert(this.state.klient)
        //alert(axios.defaults.headers.common.Authorization)
    }

    pobierzOddzialy = () => {
        Services.getOddzialy()
            .then((response) => {
                if (response.status === 200) {

                    console.log("get Oddzialy ok");

                    this.setState({oddzialy: response.data.sort(
                            (a: any, b: any) => {
                                return a.adres.localeCompare(
                                    b.adres,
                                );
                            },
                        ).map((oddzial: any) => {return oddzial}), loading: false});

                }
            }).catch((error) => {
            this.setState({loading: false});
            console.log('get Oddzialy error');
        })
    }

    pobierzKlient = () => {
        this.setState({loading: true});

        //alert(axios.defaults.headers.common.Authorization)

        Services.getKlient(getCurrentUser().email)
            .then((response) => {
                if (response.status === 200) {

                    console.log("get Klient ok");

                    this.setState({user: response.data, isEdit: false, czyFirma: !isEmpty(response.data.nrIdent), loading: false});

                    // this.pobierzOddzialy();

                }
            }).catch((error) => {
            this.setState({loading: false});
            console.log('get Klient error');
        })
    }

    zmienHaslo = () => {
        this.setState({zapisywanie: true});

        const data = {
            //@ts-ignore
            'login': this.state.user.username,
            'newPassword': this.state.noweHaslo,
            // 'powtorzHaslo': this.state.powNoweHaslo,
            'oldPassword': this.state.stareHaslo
        }

        if(this.state.noweHaslo === this.state.powNoweHaslo){
            Services.SprawdzHaslo(this.state.noweHaslo).then((response) => {
                if (response.status === 200){
                    this.zmienHasloService(data);
                }
            }).catch((error) => {
                this.setState({zapisywanie: false});
                NotificationManager.error(error.response.data.message, Lang[kraj].bladZmianyHasla, 5000);
            })
        } else {
            this.setState({zapisywanie: false});
            NotificationManager.error('', Lang[kraj].rozneHasla, 5000);
        }
    }

    usunKonto = () => {
        this.setState({usuwanieKonta: true});
        let dane = {
            haslo: this.state.haslo
        }

        // console.log(dane)

        Services.UsunKontoKlienta(dane).then((response) => {
            this.setState({showUsunKonto: false, usuwanieKonta: false});
            setInterval(() => logOut(), 10500);
            // todo
            NotificationManager.info('Aby dokończyć proces usuwania konta, kliknij w link wysłany na adres email przypisany do twojego konta.\n Za chwilę nastąpi wylogowanie.', Lang[kraj].usuwanieKonta, 10000);
            // logOut();
        }).catch((e) => {
            this.setState({usuwanieKonta: false});
            NotificationManager.error(e.response.data.message, Lang[kraj].blad, 5000);
        })
    }

    handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
        const {name, value}: any = e.target;
        let input = this.state.user;
        input[name]=value;
        this.setState({user: input, isEdit: true});
        console.log(name, value)
    };

    zmienHasloService = (data: any) => {
        this.setState({zapisywanie: true});

        Services.ZmianaHasla(data)
            .then((response) => {
                if (response.status === 200) {

                    //this.setState({onRegister: true, registerLoading: false})
                    console.log("Zmiana hasla ok");
                    NotificationManager.info(Lang[kraj].pomyslnieZmienionoHaslo, Lang[kraj].zmienionoHaslo, 5000);

                    //@ts-ignore
                    let token = 'Basic ' + btoa(this.state.user.email + ':' + this.state.noweHaslo);
                    axios.defaults.headers.common.Authorization = token;
                    let sesionStorage = getCurrentUser();
                    sesionStorage['token'] = token;

                    sessionStorage.setItem('isUserLoggedMM',
                        JSON.stringify(sesionStorage)
                    )

                    this.setState({
                        stareHaslo: '',
                        noweHaslo: '',
                        powNoweHaslo: '',
                        zapisywanie: false
                    })

                    //window.location.reload();

                    //@ts-ignore
                    //this.zalogujPonownie(this.state.user.email,this.state.noweHaslo);

                }
            }).catch((error) => {
            //this.setState({registerLoading: false})
            //alert(error.response.data.message);
            this.setState({zapisywanie: false});
            NotificationManager.error(error.response.data.message, Lang[kraj].bladZmianyHasla, 5000);
            console.log('Zmiana hasla error');
        })
    }

    // onChangeSklep = (e: any) => {
    //     //@ts-ignore
    //     console.log(e.target.value, this.state.user.kraj, this.state.user.id);
    //
    //     //@ts-ignore
    //     Services.putKlientSklep(this.state.user.id, this.state.user.kraj, e.target.value)
    //         .then((response) => {
    //             if (response.status >= 200 && response.status <= 300) {
    //
    //                 //this.setState({onRegister: true, registerLoading: false})
    //                 console.log("Zmiana Sklepu ok");
    //                 NotificationManager.info("Pomyślnie zmieniono twój sklep", 'Zmieniono sklep', 5000);
    //
    //                 this.pobierzKlient();
    //
    //             }
    //         }).catch((error) => {
    //         //this.setState({registerLoading: false})
    //         //alert(error.response.data.message);
    //         NotificationManager.error(error.response.data.message, 'Błąd zmiany sklepu', 5000);
    //         console.log('Zmiana Sklepu error');
    //     })
    // }

    aktualizujDane = () => {
        let user = this.state.user;
        let userId = user.id;
        let dane = {
            "imie": user.imie,
            "nazwisko": user.nazwisko,
            "telefon": user.telefon,
            "kodPocztowy": user.kodPocztowy,
            "miejscowosc": user.miejscowosc,
            "ulica": user.ulica,
            "nrDomu": user.nrDomu,
            "nrMieszkania": user.nrMieszkania,
            "firma": user.firma,
            "firma2": user.firma2,
            "czyFirma": this.state.czyFirma
        }

        console.log(dane)

        Services.aktualizujDane(dane).then((response) => {
            this.setState({isEdit: false});
            // todo
            NotificationManager.info("Pomyślnie zaktualizowano dane konta", 'Zaktualizowano dane', 5000);
        }).catch((e) => {
            NotificationManager.error(e.response.data.message, Lang[kraj].bladAktualizacjiDanych, 5000);
        })
    }

    closeForm = () => {
        this.setState({showUsunKonto: false, haslo: ''});
    }

    // onChangeInput = (key: string, value: string) => {
    //     // this.setState({[key]: value});
    // };

    render() {

        let {user, stareHaslo, noweHaslo, powNoweHaslo, oddzialy, isEdit, loading, czyFirma, zapisywanie, showUsunKonto, usuwanieKonta} = this.state;

        return <section id={'account'}>
            <h3>{Lang[kraj].mojeKonto}</h3>
            {/*<hr/>*/}

            {(loading)?
                <h5>{Lang[kraj].ladowanie}...</h5>
                :
                <div className={'register-form'}>
                    {
                        //@ts-ignore
                        (czyFirma) ?
                            <div className={'row mt-4'}>
                                <div className={'col-sm-3'}>{Lang[kraj].email}:</div>
                                <div className={'col-sm-8'}>
                                    <input type={'text'} className={'form-control'}
                                        //@ts-ignore
                                           value={user.email}
                                        // onChange={this.handleOnChange}
                                           name={'email'} disabled/>
                                </div>
                                <div className={'col-sm-1'}/>
                                <div className={'col-sm-3'}>{Lang[kraj].firma}:</div>
                                <div className={'col-sm-8'}>
                                    <input type={'text'} className={'form-control'}
                                        //@ts-ignore
                                           value={user.firma}
                                           onChange={this.handleOnChange}
                                           name={'firma'}/>
                                </div>
                                <div className={'col-sm-1'}/>
                                <div className={'col-sm-3'}>{Lang[kraj].firmaCd}:</div>
                                <div className={'col-sm-8'}>
                                    <input type={'text'} className={'form-control'}
                                        //@ts-ignore
                                           value={user.firma2}
                                           onChange={this.handleOnChange}
                                           name={'firma2'}/>
                                </div>
                                <div className={'col-sm-3'}>{Lang[kraj].nip}:</div>
                                <div className={'col-sm-8'}>
                                    <input type={'text'} className={'form-control'}
                                        //@ts-ignore
                                           value={user.nrIdent}
                                        // onChange={this.handleOnChange}
                                           name={'nrIdent'} disabled/>
                                </div>
                                <div className={'col-sm-3'}>{Lang[kraj].kraj}:</div>
                                <div className={'col-sm-8'}>
                                    <input type={'text'} className={'form-control'}
                                        //@ts-ignore
                                           value={user.kraj}
                                        // onChange={this.handleOnChange}
                                           name={'kraj'} disabled/>
                                </div>
                                <div className={'col-sm-3'}>{Lang[kraj].telefon}:</div>
                                <div className={'col-sm-8'}>
                                    <input type={'text'} className={'form-control'}
                                        //@ts-ignore
                                           value={user.telefon}
                                           onChange={this.handleOnChange}
                                           name={'telefon'}/>
                                </div>
                                <div className={'col-sm-1'}/>
                                <div className={'col-sm-3'}>{Lang[kraj].kodPocztowy}:</div>
                                <div className={'col-sm-2'}>
                                    <InputMask mask={[/\d/, /\d/, '-', /\d/, /\d/, /\d/]} className={'form-control'} style={{maxWidth: '90px', marginBottom: '10px'}}
                                        //@ts-ignore
                                               value={user.kodPocztowy}
                                               onChange={this.handleOnChange}
                                               name={'kodPocztowy'}
                                    />
                                </div>

                                <div className={'col-sm-2'}>{Lang[kraj].miasto}:</div>
                                <div className={'col-sm-4'}>
                                    <input type={'text'} className={'form-control'}
                                        //@ts-ignore
                                           value={user.miejscowosc}
                                           onChange={this.handleOnChange}
                                           name={'miejscowosc'}/>
                                </div>
                                <div className={'col-sm-1'}/>
                                <div className={'col-sm-3'}>{Lang[kraj].nazwaUlicy}:</div>
                                <div className={'col-sm-8'}>
                                    <input type={'text'} className={'form-control'}
                                        //@ts-ignore
                                           value={user.ulica}
                                           onChange={this.handleOnChange}
                                           name={'ulica'}/>
                                </div>
                                <div className={'col-sm-3'}>{Lang[kraj].nrDomu}:</div>
                                <div className={'col-sm-8'}>
                                    <input required type={'text'} className={'form-control'} style={{maxWidth: '120px'}}
                                           value={user.nrDomu}
                                           onChange={this.handleOnChange}
                                           name={'nrDomu'}/>
                                </div>
                                <div className={'col-sm-3'}>{Lang[kraj].nrMieszkania}:</div>
                                <div className={'col-sm-8'}>
                                    <input type={'text'} className={'form-control'} style={{maxWidth: '120px'}}
                                           value={user.nrMieszkania}
                                           onChange={this.handleOnChange}
                                           name={'nrMieszkania'}/>
                                </div>
                                {/*<div className={'col-sm-1'}/>*/}
                                {/*<div className={'col-sm-3'}>Twój sklep:</div>*/}
                                {/*<div className={'col-sm-8'}>*/}
                                {/*    <select className={'form-control'}*/}
                                {/*            placeholder={'Wybierz...'}*/}
                                {/*            onChange={this.onChangeSklep}*/}
                                {/*        //@ts-ignore*/}
                                {/*            value={((user.numag) !== 0) ? user.numag : null}*/}
                                {/*    >*/}
                                {/*        <option value="" disabled selected>Wybierz twój sklep...</option>*/}
                                {/*        {*/}
                                {/*            oddzialy.map((oddzial: any) => (*/}
                                {/*                <option value={oddzial.numag}>{oddzial.adres}</option>*/}
                                {/*            ))*/}
                                {/*        }*/}
                                {/*    </select>*/}
                                {/*</div>*/}
                                <div className={'col-sm-1'}/>
                                <div className={'col-sm-12 text-right mt-4'}>
                                    <button disabled={!isEdit} onClick={this.aktualizujDane} className={'btn btn-custom-secondary'}>{Lang[kraj].aktualizujDane}</button>
                                </div>
                            </div>
                            :
                            <div className={'row mt-4'}>
                                <div className={'col-sm-3'}>{Lang[kraj].imie}:</div>
                                <div className={'col-sm-8'}>
                                    <input type={'text'} className={'form-control'}
                                        //@ts-ignore
                                           value={user.imie}
                                           onChange={this.handleOnChange}
                                           name={'imie'}/>
                                </div>
                                <div className={'col-sm-3'}>{Lang[kraj].nazwisko}:</div>
                                <div className={'col-sm-8'}>
                                    <input type={'text'} className={'form-control'}
                                        //@ts-ignore
                                           value={user.nazwisko}
                                           onChange={this.handleOnChange}
                                           name={'nazwisko'}/>
                                </div>
                                <div className={'col-sm-1'}/>
                                <div className={'col-sm-3'}>{Lang[kraj].kodPocztowy}:</div>
                                <div className={'col-sm-2'}>
                                    <InputMask mask={[/\d/, /\d/, '-', /\d/, /\d/, /\d/]} className={'form-control'} style={{maxWidth: '90px', marginBottom: '10px'}}
                                        //@ts-ignore
                                               value={user.kodPocztowy}
                                               onChange={this.handleOnChange}
                                               name={'kodPocztowy'}
                                    />
                                </div>

                                <div className={'col-sm-2'}>{Lang[kraj].miasto}:</div>
                                <div className={'col-sm-4'}>
                                    <input type={'text'} className={'form-control'}
                                        //@ts-ignore
                                           value={user.miejscowosc}
                                           onChange={this.handleOnChange}
                                           name={'miejscowosc'}/>
                                </div>

                                <div className={'col-sm-1'}/>
                                <div className={'col-sm-3'}>{Lang[kraj].nazwaUlicy}:</div>
                                <div className={'col-sm-8'}>
                                    <input type={'text'} className={'form-control'}
                                        //@ts-ignore
                                           value={user.ulica}
                                           onChange={this.handleOnChange}
                                           name={'ulica'}/>
                                </div>
                                <div className={'col-sm-3'}>{Lang[kraj].nrDomu}:</div>
                                <div className={'col-sm-8'}>
                                    <input required type={'text'} className={'form-control'} style={{maxWidth: '120px'}}
                                           value={user.nrDomu}
                                           onChange={this.handleOnChange}
                                           name={'nrDomu'}/>
                                </div>
                                <div className={'col-sm-3'}>{Lang[kraj].nrMieszkania}:</div>
                                <div className={'col-sm-8'}>
                                    <input type={'text'} className={'form-control'} style={{maxWidth: '120px'}}
                                           value={user.nrMieszkania}
                                           onChange={this.handleOnChange}
                                           name={'nrMieszkania'}/>
                                </div>
                                <div className={'col-sm-3'}>{Lang[kraj].telefon}:</div>
                                <div className={'col-sm-8'}>
                                    <input type={'text'} className={'form-control'}
                                        //@ts-ignore
                                           value={user.telefon}
                                           onChange={this.handleOnChange}
                                           name={'telefon'}/>
                                </div>
                                {/*<div className={'col-sm-1'}/>*/}
                                {/*<div className={'col-sm-3'}>Twój sklep:</div>*/}
                                {/*<div className={'col-sm-8'}>*/}
                                {/*    <select className={'form-control'}*/}
                                {/*            placeholder={'Wybierz...'}*/}
                                {/*            onChange={this.onChangeSklep}*/}
                                {/*        //@ts-ignore*/}
                                {/*            value={((user.numag) !== 0) ? user.numag : null}*/}
                                {/*    >*/}
                                {/*        <option value="" disabled selected>Wybierz twój sklep...</option>*/}
                                {/*        {*/}
                                {/*            oddzialy.map((oddzial: any) => (*/}
                                {/*                <option value={oddzial.numag}>{oddzial.adres}</option>*/}
                                {/*            ))*/}
                                {/*        }*/}
                                {/*    </select>*/}
                                {/*    /!*<input type={'text'} className={'form-control'}*!/*/}
                                {/*    /!*    // value={inputValues.login}*!/*/}
                                {/*    /!*    // onChange={handleOnChange}*!/*/}
                                {/*    /!*    // name={'login'}*!/*/}
                                {/*    /!*       autoComplete="off"*!/*/}
                                {/*/>*/}
                                {/*</div>*/}
                                <div className={'col-sm-1'}/>
                                <div className={'col-sm-12 text-right mt-4'}>
                                    <button disabled={!isEdit} onClick={this.aktualizujDane} className={'btn btn-custom-secondary'}>{Lang[kraj].aktualizujDane}</button>
                                </div>
                            </div>
                    }
                    <hr/>
                    <h3>{Lang[kraj].zmianaHasla}</h3>
                    <div className={'row mt-4'}>
                        <div className={'col-sm-3'}>{Lang[kraj].stareHaslo}:</div>
                        <div className={'col-sm-8'}>
                            <input type={'password'} className={'form-control'}
                                   value={stareHaslo}
                                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({stareHaslo: e.target.value})}
                                //name={'login'}
                            />
                        </div>
                        <div className={'col-sm-3'}>{Lang[kraj].noweHaslo}:</div>
                        <div className={'col-sm-8'}>
                            <input type={'password'} className={'form-control'}
                                   value={noweHaslo}
                                // onChange={handleOnChange}
                                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({noweHaslo: e.target.value})}
                                //name={'login'}
                            />
                        </div>
                        <div className={'col-sm-3'}>{Lang[kraj].noweHasloPowtorz}:</div>
                        <div className={'col-sm-8'}>
                            <input type={'password'} className={'form-control'}
                                   value={powNoweHaslo}
                                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({powNoweHaslo: e.target.value})}
                                //name={'login'}
                            />
                        </div>
                        <div className={'col-sm-12 text-right mt-4'}>
                            <button disabled={zapisywanie} onClick={this.zmienHaslo} className={'btn btn-custom-secondary'}>{(zapisywanie)? <><i className="fa fa-spinner fa-spin"/> {Lang[kraj].zapisywanie}...</> : Lang[kraj].zmienHaslo}</button>
                        </div>
                    </div>

                    <hr/>
                    {/*<h3>Konto</h3>*/}
                    {/*<div className={'row mt-4'}>*/}
                    {/*    <div className={'col-sm-12 text-right mt-4'}>*/}
                    {/*        <button disabled={zapisywanie} onClick={() => this.setState({showUsunKonto: true})} className={'btn btn-custom-secondary'}>{(zapisywanie)? <><i className="fa fa-spinner fa-spin"/> zapisywanie...</> : 'Usuń konto'}</button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <Modal show={showUsunKonto} onHide={this.closeForm}>
                        <Modal.Header>
                            <Modal.Title>{Lang[kraj].usuwanieKonta}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Label>{Lang[kraj].abyUsunacKontoWprowadzHaslo}:</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder={Lang[kraj].wpiszHasloDoKonta}
                                autoFocus
                                onChange={(e) => this.setState({haslo: e.target.value})}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.closeForm}>
                                {Lang[kraj].anuluj}
                            </Button>
                            <Button variant="primary" onClick={this.usunKonto} disabled={usuwanieKonta}>
                                {Lang[kraj].usunKonto}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </section>
    }



}

export default Account;

