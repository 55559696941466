import * as React from 'react';
import {Main} from "../components/Main";
import {Menu} from "../components/userPanelComponents/Menu";
import {Account} from "../components/userPanelComponents/Account";
import {Card} from "../components/userPanelComponents/Card";
import {Box} from "../components/sharedComponents/Box";

interface Props {
    active: number
}

export const UserPanel = (props: Props) => {

    return <Main active={'USER'}>
        <Box>
            <div className={'userPanel'}>
                <div className={'row'}>
                    <div className={'col-sm-2'}>
                        <Menu active={props.active}/>
                    </div>
                    <div className={'col-sm-10'}>
                        {props.active === 1 && <Account/>}
                        {props.active === 2 && <Card/>}
                        {props.active === 3 && "Reklamacje"}
                    </div>
                </div>
            </div>
        </Box>
    </Main>

}

UserPanel.defaultProps = {}

