import * as React from 'react';
import {Link} from "react-router-dom";
import {Main} from "../components/Main";
import {Box} from "../components/sharedComponents/Box";
import {NotificationManager} from "react-notifications";
import Services from "../utilities/Services";
import Lang from "../utilities/Language";
import {kraj} from "../utilities/Utilities";

interface MatchParams {
    path: string;
}

export interface RouteComponentProps<MatchParams> {
    match: any;
    history: any;
}

interface Props extends RouteComponentProps<MatchParams> {
}

export class NewPassword extends React.Component<Props> {

    state={
        zmianaSuccess: false,
        newPassword: '',
        repNewPassword: '',
        token: '',
        login: ''
    };

    componentDidMount() {

        let {match} = this.props;

        if (match)
            if (match.params)
                if (match.params.token)
                {
                    this.setState({login: match.params.email, token: match.params.token});
                    //this.sprawdzAktywacje(match.params.id);
                }


    }

    onChangePas = (e: any) => {
        this.setState({newPassword: e.target.value});
    }

    onChangeRepPas = (e: any) => {
        this.setState({repNewPassword: e.target.value});
    }

    onZapiszHaslo = (e: React.FormEvent<any>) => {
        e.preventDefault()
        let {newPassword, repNewPassword} = this.state;

        if((newPassword===repNewPassword) && (newPassword.length>0)){

            Services.SprawdzHaslo(newPassword).then((response) => {
                if (response.status === 200){
                    this.zapiszNoweHaslo(this.state.login, this.state.token, this.state.newPassword);
                }
            }).catch((error) => {
                NotificationManager.error(error.response.data.message, Lang[kraj].bladZmianyHasla, 5000);
            })

        }
        else
        {
            NotificationManager.error(Lang[kraj].sprawdzHasla, Lang[kraj].bladZapisu, 5000);
        }
    }

    zapiszNoweHaslo = (login: string, token: string, haslo: string) => {
        Services.ZmianaHaslaReset(login, token, haslo)
            .then((response) => {
                if (response.status === 200) {

                    //this.setState({onRegister: true, registerLoading: false})
                    console.log("Zapis nowego hasla ok");
                    this.setState({zmianaSuccess: true});

                }
            }).catch((error) => {
            //this.setState({registerLoading: false})
            //alert(error.response.data.message);
            NotificationManager.error('', Lang[kraj].bladZmianyHasla, 5000);
            console.log('Zapis nowego hasla error');
        })
    }

    render() {
        let {zmianaSuccess} = this.state;
        return( <Main active={'LOGIN'}>
            <Box>

                {(!zmianaSuccess)?
                    <>
                        <h3>Ustaw nowe hasło</h3>
                        <p>Wprowadź nowe hasło aby przywrócić dostęp do konta.</p>
                        <form onSubmit={this.onZapiszHaslo}>
                            <section className={'register-form'}>
                                <div className={'row'}>
                                    <div className={'col-sm-2'}>
                                        <span>Nowe hasło:</span>
                                    </div>
                                    <div className={'col-sm-10'}>
                                        <input type={'password'} className={'form-control'}
                                            //pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,}"
                                            // value={inputValues.login}
                                            required
                                               title="Minimum 8 znaków, duże i małe litery oraz znaki specjalne"
                                             onChange={this.onChangePas}
                                               />
                                    </div>
                                    <div className={'col-sm-2'}>
                                        <span>Powtórz hasło:</span>
                                    </div>
                                    <div className={'col-sm-10'}>
                                        <input type={'password'} className={'form-control'}
                                               // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,}"
                                            // value={inputValues.login}
                                               required
                                               title="Minimum 8 znaków, duże i małe litery oraz znaki specjalne (@#$%^&+=)"
                                             onChange={this.onChangeRepPas}
                                               />
                                    </div>
                                    <div className={'col-sm-2'}/>
                                </div>
                            </section>
                            <div className={'buttons-line'}>
                                <button className={'btn btn-custom-secondary'}>Zapisz hasło</button>
                            </div>
                        </form>
                    </>
                    :
                    <>
                        <h3 style={{color: 'green'}}><i className="fa fa-check" style={{color: 'green'}}/> Hasło zostało zapisane</h3>
                        <p>Hasło do twojego konta zostało poprawnie zmienione. Możesz teraz zalogować się do twojego konta.</p>

                        <Link to={'/login'}><span className={'password-lost'} style={{color: '#E20214'}}>Przejdź do logowania</span></Link>

                    </>

                }

            </Box>
        </Main>
        );
    }
}

export default NewPassword

