import * as React from 'react';
import {Main} from "../components/Main";
import {AplikacjeMobilne} from "../components/sharedComponents/AplikacjeMobilne";
import {Box} from "../components/sharedComponents/Box";
import mobimg from "../images/mmapp.jpg";

interface Props {
}

export const Home = (props: Props) => {
//'https://www.merkurymarket.pl/gfx/merkurymarket/_thumbs/pl/merkuryslider/334/111/meble_2x,ZYR7d9mZqGOa19bo2plnaJ1liXR4lpilaqmr-jpg.webp'style={{marginTop: '100px'}}
    return (
        <>
        <Main active={'HOME'}>
            {/*<Box>*/}
            {/*<div className={'container'}>*/}
                <div style={{height: 'auto'}} >
                    <img id={'mobappimg'} style={{width: '100%', marginTop: '0', marginBottom: '10px'}} src={mobimg} className={'img-fluid'}/>
                </div>
            {/*</div>*/}
            {/*</Box>*/}

            {/*<AplikacjeMobilne wersjaPelna={true}/>*/}
        </Main>
        </>
    )
}

Home.defaultProps = {}

