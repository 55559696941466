import * as React from 'react';
import {Link} from "react-router-dom";
import Lang from "../../utilities/Language";
import {AppVersion, kraj, topWersja, typ} from "../../utilities/Utilities";

interface Props {
}

export const Footer = (props: Props) => {

    return <div id={'footer'}>
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col-6 text-left'}><small>{(typ === 1)? topWersja() : `v${AppVersion}`}</small></div>
                <div className={'col-6 text-right'}>
                    <a href={'https://www.baumax.cz/'}><small>{Lang[kraj].przejdzDoSklepu} baumax.cz</small></a>
                </div>
            </div>
        </div>
    </div>

}

Footer.defaultProps = {}

