import React from 'react';
import {Route, Switch, BrowserRouter as Router} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import './styles.scss';
import {Home} from "./modules/Home";
import {Login} from "./modules/Login";
import {Register} from "./modules/Register";
import {isLogged, getCurrentUser, logOut, topWersja} from "./utilities/Utilities";
import {UserPanel} from "./modules/UserPanel";
import {Statute} from "./modules/Statute";
import {LostPassword} from "./modules/LostPassword";
import axios from 'axios'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {Aktywacja} from "./modules/Aktywacja";
import {NewPasswordOld} from "./modules/NewPasswordOld";
import Instrukcje from "./modules/Instrukcje";
import Lang from "./utilities/Language";
import {kraj} from "./utilities/Utilities";
import AktywacjaV2 from "./modules/AktywacjaV2";
import UsunKonto from "./modules/UsunKonto";
import NewPasswordNew from "./modules/NewPasswordNew";
import NewPassword from "./modules/NewPassword";
import Packeta from "./components/sharedComponents/Packeta";
import KartyProfity from "./modules/KartyProfity";
import KartyProfityWebView from "./modules/KartyProfityWebView";

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

axios.defaults.headers.common["Top-Platform"] = "web";
axios.defaults.headers.common["Top-System"] = navigator.userAgent;
axios.defaults.headers.common["Top-Wersja"] = topWersja();
axios.defaults.headers.common["Top-Kraj"] = 'CZ';

if (isLogged()) {
    if (getCurrentUser().token)
        if (getCurrentUser().token.indexOf('Basic') > -1) {
            axios.defaults.headers.common.Authorization = getCurrentUser().token;
            //OfertaService.updateSieciHandloweInLS(LoginService.getCurrentUser().kontos);
            //localStorage.setItem(localStorageTypes.CURRENT_CZY_ODCZYTANO_POWIADOMIENIA,"true")
        } else {
            axios.defaults.headers.common.Authorization = '';
            logOut();
        }
}


axios.interceptors.request.use(function (config) {
    document.body.classList.add('loading-indicator');
    return config
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    setTimeout(() => {
        document.body.classList.remove('loading-indicator');
    }, 330);
    return response;
}, function (error) {
    setTimeout(() => {
        document.body.classList.remove('loading-indicator');
    }, 330);
    if (error.response.request.responseType === 'blob') {
        console.log('BLOB')
    } else {
        if (Boolean(error.response)) {
            console.log(error.response);
            console.log(error.response.data.message)
            //NotificationManager.error('Brak komunikacji z serwerem', 'Wystąpił błąd podczas działania aplikacji', 5000);
            if (!Boolean(error.response.data.message)) {
                NotificationManager.error(Lang[kraj].kodBledu + ' ' + error.response.status + ', error: ' + error.response.statusText, Lang[kraj].kodBleduInfo, 5000);
            } else {
                if (error.response.data.message === "User is disabled") {
                    NotificationManager.error(Lang[kraj].aktywujKontoNotifi, Lang[kraj].kontoJestNieaktywne, 5000);
                } else {
                    if (error.response.data.message === "Bad credentials") {
                        NotificationManager.error("", Lang[kraj].niepoprawneDaneLogowania, 5000);
                    } else {
                        NotificationManager.error(error.response.data.message, Lang[kraj].kodBleduInfo, 5000);
                    }
                }
            }
            return Promise.reject(error);
        } else {
            NotificationManager.error(Lang[kraj].brakKomunikacjiZSerwerem, Lang[kraj].kodBleduInfo, 5000);
            return Promise.reject(error);
        }
    }
});

function App() {
    return (
        <Router>
            <NotificationContainer/>
            <Switch>
                <Route exact path={'/'} component={Home}/>
                <Route exact path={'/konto/potwierdz/:id'} render={(props) => <Aktywacja {...props} />}/>
                <Route exact path={'/konto/v2/aktywuj/:email/:token'} render={(props) => <AktywacjaV2 {...props} />}/>
                <Route exact path={'/new-password/:token'} render={(props) => <NewPasswordOld {...props} />}/>
                <Route exact path={'/new-password/:email/:token'} render={(props) => <NewPassword {...props} />}/>
                <Route exact path={'/konto/v2/new-password/:email/:token'} render={(props) => <NewPasswordNew {...props} />}/>
                <Route exact path={'/konto/usunKonto/:email/:token'} render={(props) => <UsunKonto {...props} />}/>
                <Route path={'/login'} component={Login}/>
                <Route path={'/lost-password'} component={LostPassword}/>
                <Route exact path={'/register'} component={Register}/>
                <Route path={'/register/:migracja'} render={(props) => <Register {...props} />}/>
                <Route path={'/pravidla'} component={Statute}/>
                <Route path={'/instrukce'} component={Instrukcje}/>
                <Route path={'/packeta'} component={Packeta}/>
                <Route path={'/kartyProfity'} component={KartyProfity}/>
                <Route path={'/kartyProfityWebView'} component={KartyProfityWebView}/>
                <Route path={'/konto'} render={isLogged() ? () => <UserPanel active={1}/> : () => <Login/>}/>
                <Route path={'/karta'} render={isLogged() ? () => <UserPanel active={2}/> : () => <Login/>}/>
                <Route path={'/reklamacje'} render={isLogged() ? () => <UserPanel active={3}/> : () => <Login/>}/>
            </Switch>
        </Router>
    );
}

export default App;
