import * as React from 'react';
import Main from "../components/Main";
import {Col, Row} from "react-bootstrap";
import karta from "./../images/cz_karta klienta_KSK_tekst7.webp";
import kartaProfi from "./../images/cz_karta klienta_profi_tekst9.webp";

const KartyProfity = () => {
    return(
        <Main active={'KARTY_PROFITY'}>
            <div className={'kartyProfity'}>
            <Row>
                <Col className={'colLeft'}>
                    <div style={{position: 'relative'}}>
                        <img src={karta} style={{width: '100%'}}/>
                        {/*<div style={{position: 'absolute', bottom: '5px', left: '10px', color: 'white', fontSize: '1.8vw'}}>*/}
                        {/*    Karta Stałego Klienta*/}
                        {/*    <h5 style={{fontSize: '2vw', fontWeight: 'bold', margin: '0'}}>DLA KAŻDEGO</h5>*/}
                        {/*</div>*/}
                    </div>
                    <div className={'boxKartyProfity'} style={{color: 'red'}}>
                        <span style={{fontSize: '16px', fontWeight: 'bold'}}>Sleva až</span>
                        <div style={{fontSize: '8vw', fontWeight: 'bold', textAlign: 'center'}}>10%</div>
                    </div>
                    <div className={'boxKartyProfity'} style={{color: 'black'}}>
                        <div className={'srednie'}>Přístup k aktuálním akcím</div>
                        <div className={'srednie'}><b>S kartou stálého zákazníka výhodněji</b></div>
                        <div className={'male'}>(zboží označené v prodejnách)</div>
                        <div className={'srednie'}><b>Výhodné ceny s aplikací</b></div>
                    </div>
                    <div className={'boxKartyProfity'} style={{color: 'black'}}>
                        <div className={'srednie'}>Rychlé a snadné nákupy v aplikaci</div>
                        <div className={'male'}>S doručením domů nebo vyzvednutím v prodejně</div>
                    </div>
                    <div className={'boxKartyProfity'} style={{color: 'black'}}>
                        <div className={'srednie'}>Přístup k historii nákupů</div>
                        <div className={'male'}>Máte pod kontrolou své výdaje</div>
                    </div>
                    {/*<div className={'regulamin'}>*/}
                    {/*    Podrobnosti o akcii sú uvedené <b>v pravidlách vernostného programu „Karta stáleho zákazníka”</b>*/}
                    {/*</div>*/}
                </Col>
                <Col className={'colRigth'}>
                    <div style={{position: 'relative'}}>
                        <img src={kartaProfi} style={{width: '100%'}}/>
                        {/*<div style={{position: 'absolute', bottom: '5px', left: '10px', color: 'white', fontSize: '1.8vw'}}>*/}
                        {/*    Karta Stałego Klienta*/}
                        {/*    <h5 style={{fontSize: '2vw', fontWeight: 'bold', margin: '0'}}>DLA FIRM - PROFI</h5>*/}
                        {/*</div>*/}
                    </div>
                    <div className={'boxKartyProfity'} style={{color: 'black'}}>
                        <div style={{fontSize: '5vw', fontWeight: 'bold', textAlign: 'center'}}><span style={{fontSize: '16px'}}>Sleva až </span> 7%</div>
                        <div style={{fontSize: '5vw', fontWeight: 'bold', textAlign: 'center'}}><span style={{fontSize: '16px'}}>+ slevový kód v aplikaci </span> 5%</div>
                        {/*<div className={'male'}>Zberajte body a vymeňte ich za zľavové kupóny</div>*/}
                    </div>
                    <div className={'boxKartyProfity'} style={{color: 'black'}}>
                        <div className={'srednie'}>Přístup k aktuálním akcím</div>
                        <div className={'srednie'}><b>S kartou stálého zákazníka výhodněji</b></div>
                        <div className={'male'}>(zboží označené v prodejnách)</div>
                        <div className={'srednie'}><b>Výhodné ceny s aplikací</b></div>
                    </div>
                    <div className={'boxKartyProfity'} style={{color: 'black'}}>
                        <div className={'srednie'}>Rychlé a snadné nákupy v aplikaci</div>
                        <div className={'male'}>S doručením domů nebo vyzvednutím v prodejně</div>
                    </div>
                    <div className={'boxKartyProfity'} style={{color: 'black'}}>
                        <div className={'srednie'}>Přístup k historii nákupů</div>
                        <div className={'male'}>Máte pod kontrolou své výdaje</div>
                    </div>
                    <div className={'boxKartyProfity'} style={{color: 'black'}}>
                        <div className={'srednie'}>Speciální nabídka</div>
                        <div className={'male'}>Individuální cenové nabídky</div>
                    </div>
                    <div className={'boxKartyProfity'} style={{color: 'black'}}>
                        <div className={'srednie'}>Snadné vrácení zboží</div>
                        <div className={'male'}>Nepoužité zboží můžete vrátit</div>
                    </div>
                    {/*<div className={'boxKartyProfity'} style={{color: 'black'}}>*/}
                    {/*    <div className={'srednie'}>Doprava tovaru v deň nákupu</div>*/}
                    {/*    <div className={'male'}>Ceníme si váš čas </div>*/}
                    {/*</div>*/}
                    {/*<div className={'regulamin'}>*/}
                    {/*    Podrobnosti o akcii sú uvedené <b>v pravidlách vernostného programu „Karta stáleho zákazníka - PROFI”</b>*/}
                    {/*</div>*/}
                </Col>
            </Row>
            </div>
        </Main>
    )
}

export default KartyProfity;
