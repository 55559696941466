const Lang = ({
    'pl-PL': {
        login: 'Login',
        email: 'E-mail',
        haslo: 'Hasło',
        osobaFizyczna: 'Osoba fizyczna',
        firma: 'Firma',
        firmaCd: 'Firma c.d.',
        typKonta: 'Typ konta',
        powtorzHaslo: 'Powtórz hasło',
        imieNazwisko: 'Imię i nazwisko',
        adres: 'Adres',
        adresCd: 'Adres c.d.',
        zarejestruj: 'Zarejestruj',
        logowanie: 'Logowanie',
        logowanieInfo: 'Zaloguj się, by używać Karty Stałego Klienta. Karta ta służy do gromadzenia upustu na przyszłe zakupy.',
        stronaGlowna: 'Strona główna',
        instrukcje: 'Instrukcje',
        regulamin: 'Regulamin',
        zarejestrujSie: 'Zarejestuj się',
        zalogujSie: 'Zaloguj się',
        zaloguj: 'Zaloguj',
        pobierzAplikacjeMobilna: 'Pobierz aplikację mobilną',
        pobierzAplikacjeMobilnaIMiej: 'Pobierz naszą aplikację mobilną i miej swoją e-kartę zawsze przy sobie!',
        aplikacjaDost: 'Aplikacja jest dostępna w sklepie',
        przejdzDoSklepu: 'Przejdź do sklepu',
        aktywacjaEkarty: 'Aktywacja ekarty',
        migracjaEkarty: 'Migracja z karty plastikowej',
        rejestracja: 'Rejestracja',
        rejInfo: 'W celu rejestracji wprowadź poprawnie swoje dane, po ich wysłaniu otrzymasz wiadomość z linkiem do pobrania aplikacji mobilnej Merkury Market.',
        zgodaRegulamin: 'Oświadczam, iż zapoznałem się i akceptuję Regulamin programu lojalnościowego Karta Stałego Klienta',
        zgodaWiek: 'Oświadczam, iż posiadam  co najmniej 16 lat',
        zgodaKarta: 'Zgodnie z art. 6 ust. 1 lit. a) Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia Dyrektywy 95/46/WE (Dz. Urz. UE L 119 z 04.05.2016) – dalej RODO, wyrażam zgodę na przetwarzanie moich danych osobowych w celach związanych z realizacją programu lojalnościowego Karta Stałego Klienta przez Merkury Market Spółka z ograniczoną odpowiedzialnością Sp.k. z siedzibą w Krośnie.',
        zgodaNewsletter: 'Zgodnie z art. 6 ust. 1 lit. a) RODO wyrażam zgodę na przetwarzanie moich danych osobowych w celu subskrypcji newslettera Merkury Market Spółka z ograniczoną odpowiedzialnością Sp.k. z siedzibą w Krośnie.',
        zgodaPush: 'Wyrażam zgodę na używanie telekomunikacyjnych urządzeń końcowych (np. telefonu) dla celów marketingu bezpośredniego przez Merkury Market Spółka z ograniczoną odpowiedzialnością Sp.k. z siedzibą w Krośnie.',
        zgody: 'Zgody',
        zgodyWymagane: 'Zgody są wymagane',
        daneAdresowe: 'Dane adresowe',
        uzupelnijDaneAdresowe: 'Uzupełnij dane adresowe',
        niePamietamHasla: 'Nie pamiętam hasła',
        zapomnialemHasla: 'Zapomniałem hasła',
        zapomnialemInfo: 'Podaj adres e-mail by zresetować dostęp do konta.',
        przejdzDoLogowania: 'Przejdź do logowania',
        resetujHaslo: 'Resetuj Hasło',
        mojeKonto: 'Moje konto',
        zmianaHasla: 'Zmiana hasła',
        stareHaslo: 'Stare hasło',
        noweHaslo: 'Nowe hasło',
        noweHasloPowtorz: 'Powtórz nowe hasło',
        nip: 'Nip',
        nipCz: 'DIČ',
        twojSklep: 'Twój sklep',
        wybierzTwojSklep: 'Wybierz twój sklep',
        aktualizujDane: 'Aktualizuj dane',
        zmienHaslo: 'Zmień hasło',
        karta: 'Karta',
        brakPrzypisanejKarty: 'Brak przypisanej karty',
        brakKartyInfo: 'W celu uzyskania karty, należy pobrać aplikacje na telefon i udać się do Biura Obsługi Klienta.',
        brakKartyInfo2: 'W Biurze Obsługi Klienta należy podać adres e-mail',
        wyloguj: 'Wyloguj',
        linkNieAktywnyInfo: 'Link nieaktywny, konto zostało juz aktywowane !',
        czekaj: 'Czekaj',
        pomyslnaAktywacja: 'Konto aktywowane pomyślnie',
        pomyslnaAktywacja2: 'Możesz zacząć korzystać ze swojej e-karty',
        ustawNoweHaslo: 'Ustaw nowe hasło',
        noweHasloInfo: 'Wprowadź nowe hasło aby przywrócić dostęp do konta.',
        zapiszHaslo: 'Zapisz hasło',
        sprawdzHasla: 'Sprawdź poprawność haseł',
        bladZapisu: 'Błąd zapisu',
        hasloTitle: 'Minimum 8 znaków, duże i małe litery oraz znaki specjalne',
        hasloZapisane: 'Hasło zostało zapisane',
        hasloZapisaneInfo: 'Hasło do twojego konta zostało poprawnie zmienione. Możesz teraz zalogować się do twojego konta.',
        hasloZresetowane: 'Hasło zostało zresetowane',
        hasloZresetowaneInfo: 'Na podany adres e-mail została wysłana wiadomość z linkiem do ustawienia nowego hasła.',
        bladZmianyHasla: 'Błąd zmiany hasła',
        kodBledu: 'Kod błędu',
        kodBleduInfo: 'Wystąpił błąd podczas działania aplikacji',
        aktywujKontoNotifi: 'Aktywuj konto przez link zamieszczony w wysłanej wiadomości e-mail',
        kontoJestNieaktywne: 'Konto jest nieaktywne',
        niepoprawneDaneLogowania: 'Niepoprawne dane logowania',
        brakKomunikacjiZSerwerem: 'Brak komunikacji z serwerem',
        pomyslnieZmienionoHaslo: 'Pomyślnie zmieniono hasło do konta',
        zmienionoHaslo: 'Zmieniono hasło',
        pomyslnieZmienionoTwojSklep: 'Pomyślnie zmieniono twój sklep',
        zmienionoSklep: 'Zmieniono sklep',
        bladZmianySklepu: 'Błąd zmiany sklepu',
        menu: 'Menu',
        waluta: 'PLN',
        upustStaly: 'Upust stały',
        ostatniUdzielony: 'Ostatni udzielony upust od obrotu',
        kwotaRabatuNaZakup: 'Kwota rabatu na następny zakup',
        dziekujemyZaRejestracje: 'Dziękujemy za rejestrację',
        rejestracjaPozInfo: 'Na podany adres e-mail została wysłana wiadomość z linkiem aktywacyjnym.',
        kodPocztowy: 'Kod pocztowy',
        miasto: 'Miasto',
        ladowanie: 'Ładowanie',
        nazwaKrotka: 'Nazwa skrócona',
        maksymalnieZnakow: 'Maksymalnie 20 znaków',
        bladRejestracji: 'Błąd rejestracji',
        rozneHasla: 'Podane hasła są różne',
        pobierzRegulamin: 'Pobierz regulamin w PDF',
        zaktualizowanoDane: 'Zaktualizowano dane',
        pomyslnieZaktualizowanoDane: 'Pomyślnie zaktualizowano dane konta',
        bladAktualizacjiDanych: 'Błąd aktualizacji danych',
        nrSprzedawcyPolecajacego: 'Numer Sprzedawcy polecającego'
    },
    'sk-SK': {
        login: 'Login',
        email: 'E-mail',
        haslo: 'Heslo',
        osobaFizyczna: 'Fyzická osoba',
        firma: 'Firma',
        firmaCd: 'Firma č.d.',
        typKonta: 'Typ účtu',
        powtorzHaslo: 'Zopakuj heslo',
        imieNazwisko: 'Meno a priezvisko',
        adres: 'Adresa',
        adresCd: 'Adresa č.d.',
        zarejestruj: 'Registrácia',
        logowanie: 'Prihlásenie',
        logowanieInfo: 'Prihláste sa, aby ste mohli využívať Kartu stáleho zákazníka. Karta slúži na Zaloguj się, by używać Karty Stałego Klienta. Karta slúži na získanie zliav na budúce nákupy.',
        stronaGlowna: 'Hlavná stránka',
        instrukcje: 'Inštrukcie',
        regulamin: 'Pravidlá',
        zarejestrujSie: 'Zaregistrujte sa',
        zalogujSie: 'Prihláste sa',
        zaloguj: 'Prihláste sa',
        pobierzAplikacjeMobilna: 'Stiahnite si mobilnú aplikáciu',
        pobierzAplikacjeMobilnaIMiej: 'Stiahnite si našu mobilnú aplikáciu a majte svoje ekartu stále pri sebe!',
        aplikacjaDost: 'Aplikácia je dostupná v obchode',
        przejdzDoSklepu: 'Prejdite do obchodu',
        aktywacjaEkarty: 'Aktivácia ekarty',
        migracjaEkarty: 'Presun z plastovej karty',
        rejestracja: 'Registrácia',
        rejInfo: 'Ak sa chcete zaregistrovať, zadajte správne svoje údaje, po ich odoslaní dostanete správu s odkazom na stiahnutie mobilnej aplikácie Merkury Market.',
        zgodaRegulamin: 'Vyhlasujem, že som si prečítal a akceptujem Pravidlá vernostného programu Karta stáleho zákazníka',
        zgodaWiek: 'Vyhlasujem, že mám najmenej 16 rokov',
        zgodaKarta: 'V zmysle čl. 6 sek. 1 lit. a) Nariadenie Európskeho parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe týchto údajov, ktorým sa zrušuje smernica 95/46 / EC (vestník UE L 119 zo 4. 5. 2016) - ďalej len GDPR, súhlasím so spracovaním mojich osobných údajov na účely spojené s implementáciou vernostného programu Karta stáleho zákazníka prostredníctvom Merkury SHOP S.R.O.',
        zgodaNewsletter: 'V zmysle čl. 6 sek. 1 lit. a) GDPR, súhlasím so spracovaním mojich osobných údajov na účely odoberania newslettera Merkury SHOP S.R.O.',
        zgodaPush: 'Súhlasím s používaním koncových telekomunikačných zariadení (napr. Telefónov) na účely priameho marketingu spoločnosťou Merkury SHOP S.R.O.',
        zgody: 'Súhlasy',
        zgodyWymagane: 'Súhlasy sú povinné',
        daneAdresowe: 'Údaje o adrese',
        uzupelnijDaneAdresowe: 'Vyplňte údaje o adrese',
        niePamietamHasla: 'Nepamätám heslo',
        zapomnialemHasla: 'Zabudol/a som heslo',
        zapomnialemInfo: 'Ak chcete obnoviť prístup k účtu, zadajte svoju e-mailovú adresu.',
        przejdzDoLogowania: 'Prejdite na prihlásenie',
        resetujHaslo: 'Obnoviť heslo',
        mojeKonto: 'Môj účet',
        zmianaHasla: 'Zmeniť heslo',
        stareHaslo: 'Staré heslo',
        noweHaslo: 'Nové heslo',
        noweHasloPowtorz: 'Zopakujte nové heslo',
        nip: 'DIČ',
        nipCz: 'DIČ',
        twojSklep: 'Vaša predajňa',
        wybierzTwojSklep: 'Vyberte si Vašu predajňu',
        aktualizujDane: 'Aktualizácia údajov',
        zmienHaslo: 'Zmeň heslo',
        karta: 'Karta',
        brakPrzypisanejKarty: 'Chyba pri párovaní karty',
        brakKartyInfo: 'Ak chcete získať kartu, stiahnite si aplikáciu do telefónu a prejdite do Oddelenia zákazníckych služieb.',
        brakKartyInfo2: 'V oddelení zákazníckych služieb je potrebné uviesť mailovú adresu',
        wyloguj: 'Odhlásenie',
        linkNieAktywnyInfo: 'Link je neaktívny, účet už bol aktivovaný !',
        czekaj: 'Čakajte',
        pomyslnaAktywacja: 'Účet bol úspešne aktivovaný',
        pomyslnaAktywacja2: 'Môžete začať používať svoju ekartu',
        ustawNoweHaslo: 'Nastaviť nové heslo',
        noweHasloInfo: 'Ak chcete obnoviť prístup k svojmu účtu, zadajte nové heslo.',
        zapiszHaslo: 'Uložiť heslo',
        sprawdzHasla: 'Overiť správnosť hesla',
        bladZapisu: 'Chyba pri zápise',
        hasloTitle: 'Minimálne 8 znakov, veľké a malé písmená a špeciálne znaky',
        hasloZapisane: 'Heslo bolo uložené',
        hasloZapisaneInfo: 'Heslo vášho účtu bolo úspešne zmenené. Teraz sa môžete prihlásiť do svojho účtu.',
        hasloZresetowane: 'Heslo bolo obnovené',
        hasloZresetowaneInfo: 'Na uvedenú e-mailovú adresu bola odoslaná správa s odkazom na nastavenie nového hesla.',
        bladZmianyHasla: 'Chyba zmeny hesla',
        kodBledu: 'Kód chyby',
        kodBleduInfo: 'Počas spustenia aplikácie sa vyskytla chyba',
        aktywujKontoNotifi: 'Aktivujte si účet prostredníctvom odkazu v zaslanom e-maile',
        kontoJestNieaktywne: 'Účet je neaktívny',
        niepoprawneDaneLogowania: 'Nesprávne prihlasovacie údaje',
        brakKomunikacjiZSerwerem: 'Chyba komunikácie so serverom',
        pomyslnieZmienionoHaslo: 'Heslo do účtu sa úspešne zmenilo',
        zmienionoHaslo: 'Heslo zmenené',
        pomyslnieZmienionoTwojSklep: 'Vaša predajňa bola úspešne zmenená',
        zmienionoSklep: 'Predajňa sa zmenila',
        bladZmianySklepu: 'Chyba pri zmene predajne',
        menu: 'Menu',
        waluta: 'EUR',
        upustStaly: 'Stála zľava',
        ostatniUdzielony: 'Posledná udelená zľava z obratu',
        kwotaRabatuNaZakup: 'Výška zľavy na nasledujúci nákup',
        dziekujemyZaRejestracje: 'Ďakujeme za registráciu',
        rejestracjaPozInfo: 'Na zadanú adresu bol zaslaný aktivačný mail',
        kodPocztowy: 'Poštové smerovacie číslo',
        miasto: 'Mesto',
        ladowanie: 'Nahrávanie',
        nazwaKrotka: 'Skrátený názov',
        maksymalnieZnakow: 'Maximálne 20 znakov',
        bladRejestracji: 'Chyba prihlásenia',
        rozneHasla: 'Zadané heslá sú rozdielne',
        pobierzRegulamin: 'Pravidlá na stihnutie v PDF',
        zaktualizowanoDane: '',
        pomyslnieZaktualizowanoDane: '',
        bladAktualizacjiDanych: '',
        nrSprzedawcyPolecajacego: 'Číslo odporúčajúceho predajcu'
    },
    'cs-CZ': {
        login: 'Login',
        email: 'E-mail',
        haslo: 'Heslo',
        osobaFizyczna: 'Fyzická osoba',
        firma: 'Firma',
        firmaCd: 'Firma c.d.',
        typKonta: 'Typ účtu',
        powtorzHaslo: 'Opakujte heslo',
        imieNazwisko: 'Jméno a příjmení',
        adres: 'Adresa',
        adresCd: 'Adresa c.d.',
        zarejestruj: 'Registrace',
        logowanie: 'Přihlášení',
        logowanieInfo: 'Chcete-li používat věrnostní kartu, přihlaste se. Tato karta se používá k získání slevy pro budoucí nákupy.',
        stronaGlowna: 'Hlavní stránka',
        instrukcje: 'Návod',
        regulamin: 'Pravidla',
        zarejestrujSie: 'Registrace',
        zalogujSie: 'Přihlášení',
        zaloguj: 'Přihlásit',
        pobierzAplikacjeMobilna: 'Stáhněte si mobilní aplikaci',
        pobierzAplikacjeMobilnaIMiej: 'Stáhněte si naši mobilní aplikaci a mějte svou e-kartu vždy s sebou!',
        aplikacjaDost: 'Aplikace je dostupná v obchodě',
        przejdzDoSklepu: 'Přejít do obchodu',
        aktywacjaEkarty: 'Aktivace e-karty',
        migracjaEkarty: 'Migrace z plastové karty',
        rejestracja: 'Registrace',
        rejInfo: 'Chcete-li se zaregistrovat, zadejte své údaje správně, po jejich odeslání obdržíte email s odkazem na stažení mobilní aplikace Baumax.',
        zgodaRegulamin: 'Prohlašuji, že jsem se seznámil s pravidly pro používán mobilní aplikace BM ČESKO s.r.o. a Zásady ochrany osobních údajů a přijímá je',
        zgodaWiek: 'Prohlašuji, že je mi minimálně 16 let',
        zgodaKarta: 'Souhlasím se zpracováním svých osobních údajů pro účely související s realizací věrnostního programu Karty stálého zákazníka,',
        zgodaNewsletter: 'Souhlasím se zasíláním obchodních informací formou Newsletteru prostřednictvím elektronických komunikačních prostředků',
        zgodaPush: 'Souhlasím s používáním telekomunikačních zařízení pro účely přímého marketingu',
        zgody: 'Souhlas',
        zgodyWymagane: 'Souhlas je vyžadován',
        daneAdresowe: 'Adresa',
        uzupelnijDaneAdresowe: 'Doplňte adresu',
        niePamietamHasla: 'Nepamatuji si heslo',
        zapomnialemHasla: 'Nepamatuji si heslo',
        zapomnialemInfo: 'Zadejte e-mail pro obnovení přístupu k účtu.',
        przejdzDoLogowania: 'Přejděte na přihlášení',
        resetujHaslo: 'Obnovit Heslo',
        mojeKonto: 'Můj účet',
        zmianaHasla: 'Změna hesla',
        stareHaslo: 'Staré heslo',
        noweHaslo: 'Nové heslo',
        noweHasloPowtorz: 'Opakujte nové heslo',
        nip: 'IČO',
        nipCz: 'DIČ',
        twojSklep: 'Váš obchod',
        wybierzTwojSklep: 'Vyberte svůj obchod',
        aktualizujDane: 'Aktualizovat údaje',
        zmienHaslo: 'Změnit heslo',
        karta: 'Karta',
        brakPrzypisanejKarty: 'Není přiřazena žádná karta',
        brakKartyInfo: 'Chcete-li získat kartu, stáhněte si aplikaci do telefonu a přejděte k informacím v obchodě.',
        brakKartyInfo2: 'Na informacích je třeba uvést e-mailovou adresu',
        wyloguj: 'Odshlásit',
        linkNieAktywnyInfo: 'Odkaz je neaktivní, účet již byl aktivován!',
        czekaj: 'Čekejte',
        pomyslnaAktywacja: 'Účet byl úspěšně aktivován',
        pomyslnaAktywacja2: 'Svou e-kartu můžete začít používat',
        ustawNoweHaslo: 'Zadejte nové heslo',
        noweHasloInfo: 'Chcete-li obnovit přístup ke svému účtu, zadejte nové heslo.',
        zapiszHaslo: 'Uložit heslo',
        sprawdzHasla: 'Zkontrolujte hesla',
        bladZapisu: 'Chyba zápisu',
        hasloTitle: 'Minimálně 8 znaků, velká i malá pásmena a speciální znaky',
        hasloZapisane: 'Heslo bylo uloženo',
        hasloZapisaneInfo: 'Heslo vašeho účtu bylo změněno. Nyní se můžete přihlásit ke svému účtu.',
        hasloZresetowane: 'Heslo bylo obnoveno',
        hasloZresetowaneInfo: 'Na uvedenou e-mailovou adresu byla odeslána zpráva s odkazem na nastavení nového hesla.',
        bladZmianyHasla: 'Chyba při zápisu hesla',
        kodBledu: 'Chybový kód',
        kodBleduInfo: 'Během chodu aplikace došlo k chybě',
        aktywujKontoNotifi: 'Aktivujte účet pomocí odkazu v odeslaném e-mailu',
        kontoJestNieaktywne: 'Účet je neaktivní',
        niepoprawneDaneLogowania: 'Nesprávné přihlašovací ůdaje',
        brakKomunikacjiZSerwerem: 'Žádná komunikace se serverem',
        pomyslnieZmienionoHaslo: 'Heslo účtu bylo úspěšně změněno',
        zmienionoHaslo: 'Heslo změněno',
        pomyslnieZmienionoTwojSklep: 'Váš obchod byl úspěšně změněn',
        zmienionoSklep: 'Obchod změněn',
        bladZmianySklepu: 'Chyba při změně obchodu',
        menu: 'Menu',
        waluta: 'CZK',
        upustStaly: 'Stálá sleva',
        ostatniUdzielony: 'Poslední dosažená sleva',
        kwotaRabatuNaZakup: 'Kód slevyna příští nákup',
        dziekujemyZaRejestracje: 'Děkujeme za registraci',
        rejestracjaPozInfo: 'Na uvedenou e-mailovou adresu byla odeslána zpráva s aktivačním odkazem.',
        kodPocztowy: 'PSČ',
        miasto: 'Město',
        ladowanie: 'Načítání',
        nazwaKrotka: 'Zkrácený název',
        maksymalnieZnakow: 'Max 20 znaků',
        bladRejestracji: 'Chybné přihlášení',
        rozneHasla: 'Hesla se neshodují',
        pobierzRegulamin: 'Stáhnout obchodní podmínky v PDF',
        zaktualizowanoDane: 'Údaje byli aktualizovány',
        pomyslnieZaktualizowanoDane: 'Aktualizace účtu proběhal úspěšně',
        bladAktualizacjiDanych: 'Při aktualizaci se vyskytla chyba!',
        nrSprzedawcyPolecajacego: 'Číslo prodavače',
        kontoUsunietePomyslnie: 'Účet byl úspěšně odstraněn',
        proszeCzekacUsuwanieKonta: 'Vyčkejte, váš účet bude odstraněn',
        uzupelnijBrakujaceInformacjeOKoncie: 'Doplňte chybějící informace',
        weryfikacja: 'Ověřování',
        kraj: 'Země',
        wybierzKraj: 'Vyberte stát',
        telefon: 'Telefon',
        podajNip: 'Zadajte IČO',
        nazwaUlicy: 'Název ulice',
        nrDomu: 'Popisné číslo',
        nrMieszkania: 'Číslo bytu',
        imie: 'Jméno',
        nazwisko: 'Příjmení',
        zapisywanie: 'Uložení',
        trwaRejestracja: 'Probíhá registrace',
        wystapilBladLubKontoZostaloJuzAktywowane: 'Vyskytla se chyba nebo účet byl již aktivován',
        zapisz: 'Uložit',
        usuwanieKonta: 'Odstranění',
        abyUsunacKontoWprowadzHaslo: 'Chcete-li odstranit účet, zadejte heslo k účtu',
        wpiszHasloDoKonta: 'Zadejte heslo',
        anuluj: 'Zrušit',
        usunKonto: 'Smazat účet',
        wymaganaAktualizacjaDanych: 'Vyžaduje se aktualizace údajů',
        aktualizacjaDanych: 'Aktualizace údajů',
        bladLogowania: 'Chyba při přihlašování',
        twojeKontoNieAktywowane: 'Váš účet nebyl doposud aktivován',
        blad: 'Vyskytla se chyba',
        bladMigracji: 'chyba přenosu',
        proszeCzekacAktywacjaKonta: 'Čekejte prosím, probíhá aktivace účtu',
    },
});

export default Lang;
