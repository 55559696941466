import * as React from 'react';
import {isDesktop, isIOS, isAndroid} from 'react-device-detect';

export const typ: number = 2;      // 1 -dev, 2 - prod
export const AppVersion = '1.1.143';
export const AppData = '202407011250';
export const kraj = "cs-CZ"; // "cs-CZ", "sk-SK", "pl-PL"

export const HOST = () => {
    switch (typ) {
        case 1:
            return 'http://centos7.top:8080/api/';
        case 2:
            // return '';
            return 'https://esklep.baumax.cz';
            // return 'https://stage-esklep.merkurymarket.pl';
    }
}

const AppTyp = () => {
    switch (typ) {
        case 1:
            return 'DEV';
        case 2:
            return 'PROD';
    }
}

export const topWersja = () => {
    if (isDesktop) {
        return AppVersion+'-'+AppData+'-WEB.DESKTOP-CZ-'+AppTyp()
    } else if (isAndroid) {
        return AppVersion+'-'+AppData+'-WEB.ANDROID-CZ-'+AppTyp()
    } else if (isIOS){
        return AppVersion+'-'+AppData+'-WEB.IOS-CZ-'+AppTyp()
    } else {
        return AppVersion+'-'+AppData+'-WEB.INNE-CZ-'+AppTyp()
    }
}

export const isLogged = () => {
    return sessionStorage.isUserLoggedMM !== undefined && sessionStorage.isUserLoggedMM !== null;
}

export const getCurrentUser = () => {
    if (isLogged()) {
        let user: any = sessionStorage.getItem('isUserLoggedMM');
        return JSON.parse(user)
    } else return null
};

export const logOut = () => {
    sessionStorage.removeItem('isUserLoggedMM');
    window.location.replace('/')
}

export const isEmpty = (value: any) => {
    if (value === undefined || value === null || value === '') {
        return true;
    } else {
        return false;
    }
}
