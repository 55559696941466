import * as React from 'react';
import {Main} from "../components/Main";
import imgAktywacja from "../images/ins-aktywacja.jpg";
import imgMigracja from "../images/ins-migracja.jpg";
import Lang from "../utilities/Language";
import {kraj} from "../utilities/Utilities";


export class Instrukcje extends React.Component<any> {

    render(){
        return <Main active={'INSTRUKCJE'}>

                <div className={'row'}>
                    <div className={'col-xl-6'} style={{marginBottom: '15px'}}>
                        <div id={'main-container'} style={{marginTop: '0', paddingTop: '15px'}}>
                            <h3 id={'aktywacja'} style={{textAlign: 'center'}}>{Lang[kraj].aktywacjaEkarty}</h3>
                            <img src={imgAktywacja} className={'card-img'}/>
                        </div>
                    </div>
                    <div className={'col-xl-6'} style={{marginBottom: '15px'}}>
                        <div id={'main-container'} style={{marginTop: '0', paddingTop: '15px'}}>
                            <h3 id={'migracja'} style={{textAlign: 'center'}}>{Lang[kraj].migracjaEkarty}</h3>
                            <img src={imgMigracja} className={'card-img'}/>
                        </div>
                    </div>
                </div>

        </Main>
    }

}

export default Instrukcje;
