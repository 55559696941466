import * as React from 'react';
import './formStyles.scss'

// CHECKBOX

interface checkboxProps {
    required: boolean,
    name: string,
    checked: boolean,
    handleOnChange: any
}

export const CheckBox = (props: checkboxProps) => {
    return <label className={'checkbox-container'}>
        <input type="checkbox" checked={props.checked} required={props.required} name={props.name} onChange={props.handleOnChange}/>
        <span className={"checkbox-checkmark"}/>
    </label>
}

CheckBox.defaultProps = {}

