import * as React from 'react';
import {Link} from "react-router-dom";
import logo from '../../images/logo.png'
import {isLogged, kraj} from "../../utilities/Utilities";
import Lang from "../../utilities/Language";

interface Props {
    active: string
}

export const Header = (props: Props) => {

    return <div id={'header'}>
        <nav className="navbar navbar-expand-lg">
            <div className={'container'}>
                <a className="navbar-brand" href="#">
                    <img src={logo} className={'img-fluid'}/>
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"><i className={'fa fa-bars'}/></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <Link to={'/'}>
                            <li className={props.active === 'HOME' ? "nav-item active" : "nav-item"}>
                                <a className="nav-link" href="#">{Lang[kraj].stronaGlowna}<span
                                    className="sr-only">(current)</span></a>
                            </li>
                        </Link>
                        <Link to={'/instrukce'}>
                            <li className={props.active === 'INSTRUKCJE' ? "nav-item active" : "nav-item"}>
                                <a className="nav-link" href="#">{Lang[kraj].instrukcje}<span
                                    className="sr-only">(current)</span></a>
                            </li>
                        </Link>
                        <Link to={'/kartyProfity'}>
                            <li className={props.active === 'KARTY_PROFITY' ? "nav-item active" : "nav-item"}>
                                <a className="nav-link" href="#">Karty PROFI<span
                                    className="sr-only">(current)</span></a>
                            </li>
                        </Link>
                        <Link to={'/pravidla'}>
                            <li className={props.active === 'STATUTE' ? "nav-item active" : "nav-item"}>
                                <a className="nav-link" href="#">{Lang[kraj].regulamin}</a>
                            </li>
                        </Link>
                        {isLogged() ? null
                            : <Link to={'/register'}>
                                <li className={props.active === 'REGISTER' ? "nav-item active" : "nav-item"}>
                                    <a className="nav-link" href="#">{Lang[kraj].zarejestrujSie}</a>
                                </li>
                            </Link>}
                        {isLogged() ?
                            <>
                                <li className={props.active === 'USER' ? "nav-item active" : "nav-item"}>
                                    <Link to={'/konto'}><a className="nav-link"
                                                           href="#">{JSON.parse(sessionStorage.isUserLoggedMM).username}
                                        {(Boolean(JSON.parse(sessionStorage.isUserLoggedMM).rabatKwota))?<span>{JSON.parse(sessionStorage.isUserLoggedMM).rabatKwota} {Lang[kraj].waluta}</span> : <span>0 {Lang[kraj].waluta}</span>}
                                         {/*<span>1 PLN</span>*/}
                                    </a>
                                    </Link>
                                </li>
                            </>

                            : <Link to={'/login'}>
                                <li className={props.active === 'LOGIN' ? "nav-item active" : "nav-item"}>
                                    <a className="nav-link" href="#">{Lang[kraj].zalogujSie}</a>
                                </li>
                            </Link>}

                    </ul>
                </div>
            </div>
        </nav>
    </div>
}

Header.defaultProps = {}

