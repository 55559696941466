import * as React from 'react';

// export class Packeta extends React.Component<Props, State> {
const Packeta = () => {
    // const params: any = useParams();

    // constructor(props: any) {
    //     super(props);
    //
    //     this.state = {
    //         kod: '35-330'
    //     }
    // }

    // componentDidMount() {
    //     let {match} = this.props;
    //
    //     if (match)
    //         if (match.params)
    //             if (!isEmpty(match.params.kod))
    //             {
    //                 this.setState({kod: match.params.kod})
    //             }
    // }

    // console.log(params.kod)
    return (
        <iframe src={`packeta.html` } style={{width: '100%', height: '99vh'}}></iframe>
        // <iframe src={`paczkaOrlen.html?kod=${params.kod}` } style={{width: '100%', height: '100vh'}}></iframe>
    );

}


export default Packeta

