import * as React from 'react';
import {Link} from "react-router-dom";
import {Main} from "../components/Main";
import {Box} from "../components/sharedComponents/Box";
import Services from "../utilities/Services";
import {kraj} from "../utilities/Utilities";
import Lang from "../utilities/Language";

interface Props {
}

export class LostPassword extends React.Component<any> {

    state={
        resetSuccess: false,
        email: ''
    }

    onChangeEmail = (e: any) => {
        this.setState({email: e.target.value});
    }

    onReset = (e: React.FormEvent<any>) => {
        e.preventDefault()

        Services.ResetHasla(this.state.email)
            .then((response) => {
                if (response.status === 200) {

                    //this.setState({onRegister: true, registerLoading: false})
                    console.log("Reset ok");
                    this.setState({resetSuccess: true});

                }
            }).catch((error) => {
            //this.setState({registerLoading: false})
            alert(error.response.data.message);
            console.log('Reset error');
        })
    }

    render() {
        let {resetSuccess} = this.state;

        return <Main active={'LOGIN'}>
            <Box>
                {
                    (!resetSuccess)?
                            <>
                                <h3>{Lang[kraj].zapomnialemHasla}</h3>
                                <p>{Lang[kraj].zapomnialemInfo}</p>
                                <form onSubmit={this.onReset.bind(this)}>
                                <section className={'register-form'}>
                                <div className={'row'}>
                                <div className={'col-sm-2'}>
                                <span>{Lang[kraj].email}:</span>
                                </div>
                                <div className={'col-sm-10'}>
                                <input type={'email'} required className={'form-control'}
                                // value={inputValues.login}
                                 onChange={this.onChangeEmail}
                                name={'email'}/>
                                </div>
                                <div className={'col-sm-2'}/>
                                <div className={'col-sm-10'}>
                                <Link to={'/login'}><span className={'password-lost'}>{Lang[kraj].przejdzDoLogowania}</span></Link>
                                </div>
                                </div>
                                </section>
                                <div className={'buttons-line'}>
                                <button className={'btn btn-custom-secondary'}>{Lang[kraj].resetujHaslo}</button>
                                </div>
                                </form>
                            </>
                        :
                        <>
                            <h3 style={{color: 'green'}}>{Lang[kraj].hasloZresetowane}</h3>
                            <p>{Lang[kraj].hasloZresetowaneInfo}</p>
                        </>

                }

            </Box>
        </Main>
    }



}

export default LostPassword

