import * as React from 'react';
import {Link} from "react-router-dom";
import {logOut, kraj} from "../../utilities/Utilities";
import Lang from "../../utilities/Language";

interface Props {
    active: number
}

export const Menu = (props: Props) => {

    return <>
        <section id={'menu'}>
            <h3>{Lang[kraj].menu}</h3>
            <hr/>
            <ul>
                <Link to={'/konto'}>
                    <li className={props.active === 1 ? "active" : ""}>{Lang[kraj].mojeKonto}</li>
                </Link>
                <Link to={'/karta'}>
                    <li className={props.active === 2 ? "active" : ""}>{Lang[kraj].karta}</li>
                </Link>
                {/*<Link to={'/reklamacje'}>*/}
                {/*    <li className={props.active === 3 ? "active" : ""}>Reklamacje</li>*/}
                {/*</Link>*/}
                <Link to={'#'} onClick={()=>logOut()}>
                    <li className={props.active === 4 ? "active" : ""}>{Lang[kraj].wyloguj}</li>
                </Link>
            </ul>
        </section>
    </>

}

Menu.defaultProps = {}

