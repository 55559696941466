import * as React from 'react';
import {Main} from "../components/Main";
import {Box} from "../components/sharedComponents/Box";
import Services from "../utilities/Services";
import {kraj} from "../utilities/Utilities";
import Lang from "../utilities/Language";

interface MatchParams {
    path: string;
}

export interface RouteComponentProps<MatchParams> {
    match: any;
    history: any;
}

interface Props extends RouteComponentProps<MatchParams> {
}

export class Aktywacja extends React.Component<Props>{

    state = {
        id: null,
        odpowiedz: false,
        czyPoprawnie: false,
        message: 'Błąd aktywacji !'
    };

    componentDidMount() {

        let {match} = this.props;

        if (match)
            if (match.params)
                if (match.params.id)
                {
                    this.setState({id: match.params.id});
                    this.sprawdzAktywacje(match.params.id);
                }


    }

    sprawdzAktywacje = (id: string) =>{
        Services.Aktywacja(id)
            .then((response) => {
                if (response.status === 200) {

                    //this.setState({onRegister: true, registerLoading: false})
                    console.log("Aktywacja ok");
                    this.setState({odpowiedz: true, czyPoprawnie: true});

                }
            }).catch((error) => {
            //this.setState({registerLoading: false})
            //alert(error.response.data.message);
            this.setState({odpowiedz: true, czyPoprawnie: false, message: error.response.data.message});
            console.log('Aktywacja error');
        })
    }

    render()
    {
        let {odpowiedz, czyPoprawnie, message} = this.state;

        return(<Main active={'LOGIN'}>
                <Box>
                    {(odpowiedz) ?
                        <>
                            {(czyPoprawnie)?
                                <>
                                    <h3 style={{color: 'green'}}><i className="fa fa-check" style={{color: 'green'}}/> {Lang[kraj].pomyslnaAktywacja}!</h3>
                                    <p>{Lang[kraj].pomyslnaAktywacja2}</p>
                                </>
                            :
                                <h3>{message}</h3>
                            }
                        </>
                        :
                        <>
                            <h4><i className="fa fa-spinner"/> {Lang[kraj].czekaj}...</h4>
                        </>
                    }
                </Box>
            </Main>
        );
    }
}

export default Aktywacja;


