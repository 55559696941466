import * as React from 'react';
import {Main} from "../components/Main";
import {Link} from "react-router-dom";
import Services from "../utilities/Services";
import {kraj} from "../utilities/Utilities";
import {Box} from "../components/sharedComponents/Box";
import axios from 'axios';
import Lang from "../utilities/Language";
import {NotificationManager} from "react-notifications";

export class Login extends React.Component<any> {

    state = {
        login: '',
        haslo: '',

    };

    onChangeInput = (key: string, value: string) => {
        this.setState({[key]: value});
    };

    pobierzDaneDoMigracji = (typ: string) => {
        Services.getDaneDoMigracji(typ, this.state.login, this.state.haslo).then((response) => {
            let dane = response.data;
            console.log('Dane', dane);

            dane['login'] = this.state.login;
            dane['typMigracji'] = typ;
            dane['haslo'] = this.state.haslo;

            sessionStorage.setItem('migrationData', JSON.stringify(response.data));
            window.location.replace('/register/migracja');
        }).catch((e) => {
            NotificationManager.error(Lang[kraj].bladMigracji, Lang[kraj].blad);
        })
    }

    onSubmit = (e: React.FormEvent<any>) => {
        e.preventDefault()
        this.setState({logowanie: true});

        Services.Zaloguj(this.state.login, this.state.haslo).then((response) => {

            let token = 'Basic ' + btoa(this.state.login + ':' + this.state.haslo)

            axios.defaults.headers.common.Authorization = token;

            sessionStorage.setItem('isUserLoggedMM',
                JSON.stringify(Object.assign({}, response.data, {token: token}))
            )

            window.location.replace('/konto')
            //this.props.history.push('/konto');
            this.setState({logowanie: false});

        }).catch(error => {
            //alert(error.response.data.message)

            if(error.response.data.message === 'migration/topEditoApi'){
                NotificationManager.info(Lang[kraj].wymaganaAktualizacjaDanych, Lang[kraj].aktualizacjaDanych);
                this.pobierzDaneDoMigracji('migration/topEditoApi');
            } else if (error.response.data.message === 'migration/editoTop'){
                NotificationManager.info(Lang[kraj].wymaganaAktualizacjaDanych, Lang[kraj].aktualizacjaDanych);
                this.pobierzDaneDoMigracji('migration/editoTop');
            } else if (error.response.data.message === 'migration/topAndEdito'){
                NotificationManager.info(Lang[kraj].wymaganaAktualizacjaDanych, Lang[kraj].aktualizacjaDanych);
                this.pobierzDaneDoMigracji('migration/topAndEdito');
            } else {
                if(error.response.data.message.indexOf('Twoje konto nie zostało jeszcze aktywowane') > -1){
                    this.setState({email: this.state.login, showModalBrakAktywacji: true});
                } else {
                    NotificationManager.error(error.response.data.message, Lang[kraj].bladLogowania, 5000);
                }
            }

            this.setState({logowanie: false});
        })

    }

    handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            this.onSubmit(e);
        }
    };

    render(){

        let {login, haslo} = this.state;

        return <Main active={'LOGIN'}>
            <Box>
                <h3>{Lang[kraj].logowanie}</h3>
                <p>{Lang[kraj].logowanieInfo}</p>
                <form onSubmit={this.onSubmit} onKeyPress={this.handleKeyPress}>
                    <section className={'register-form'}>
                        <div className={'row'}>
                            <div className={'col-sm-2'}>
                                <span>{Lang[kraj].login}:</span>
                            </div>
                            <div className={'col-sm-10'}>
                                <input type={'text'} className={'form-control'} value={login}
                                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeInput('login', e.target.value)}
                                       name={'login'}/>
                            </div>
                            <div className={'col-sm-2'}>
                                <span>{Lang[kraj].haslo}:</span>
                            </div>
                            <div className={'col-sm-10'}>
                                <input type={'password'} className={'form-control'} value={haslo}
                                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeInput('haslo', e.target.value)}
                                       name={'haslo'}/>
                            </div>
                            <div className={'col-sm-2'}/>
                            <div className={'col-sm-10'}>
                                <Link to={'/lost-password'}><span className={'password-lost'}>{Lang[kraj].niePamietamHasla}</span></Link>
                            </div>
                        </div>
                    </section>
                    <div className={'buttons-line'}>
                        <Link to={'/register'}><button className={'btn btn-custom-primary'}>{Lang[kraj].zarejestrujSie}</button></Link>
                        <button className={'btn btn-custom-secondary'}>{Lang[kraj].zaloguj}</button>
                    </div>
                </form>
            </Box>
        </Main>
    }

}

export default Login;
