import * as React from 'react'
import axios from 'axios'
import {HOST} from "./Utilities";

// const HOST = 'http://centos7.top:8080/api/'; //'https://ekarty.merkurymarket.pl/api/'; // 'http://centos7.top:8080/api/'
// export const kraj = "cs-CZ"; // "cs-CZ", "sk-SK", "pl-PL"

export default {

    // Register(data: any) {
    //     return axios.request({
    //         method: 'post',
    //         headers: {'Content-Type': 'application/json'},
    //         url: HOST()+`register`,
    //         data: data
    //     })
    // },

    Register(data: any) {
        return axios.request({
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            url: HOST()+'/esklep/mobile/user/registerUser',
            data: data
        })
    },

    Migracja(data: any, typMigracji: string) {
        return axios.request({
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            url: HOST()+`/esklep/mobile/user/registerUser?migration=${typMigracji}`,
            data: data
        })
    },

    SprawdzHaslo(haslo: string) {
        return axios.request({
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            url: HOST()+'/esklep/mobile/user/checkPassword',
            // url: HOST()+`/register/checkPassword`,
            data: {
                'password': haslo
            }
        })
    },

    Aktywacja(id: string) {
        return axios.request({
            method: 'get',
            url: HOST()+`/api/register/aktywujKonto/${id}`
        })
    },

    // aktywacja po nowemu
    AktywacjaV2(email: string, token: string) {
        return axios.request({
            method: 'post',
            url: HOST()+`/esklep/mobile/user/activateEmail`,
            data: {
                'email': atob(email),
                'token': token
            }
        })
    },

    ResetHasla(login: string) {
        return axios.request({
            method: 'put',
            headers: {'Content-Type': 'application/json'},
            url: HOST()+'/esklep/mobile/user/resetPassword',
            // url: HOST()+`/register/resetHasla`,
            data: {
                'login': login
            }
        })
    },

    ZmianaHaslaReset(login: string, token: string, haslo: string) {
        return axios.request({
            method: 'put',
            url: HOST()+'/esklep/mobile/user/setNewPassword',
            data: {
                'login': atob(login),
                'newPassword': haslo,
                'token': token
            }
        })
    },

    ZmianaHaslaResetOld(token: string, haslo: string) {
        return axios.request({
            method: 'post',
            url: HOST()+`/api/register/ustawNoweHaslo`,
            data: {
                'newPassword': haslo,
                'token': token
            }
        })
    },

    ZmianaHaslaResetNew(login: string, token: string, haslo: string) {
        return axios.request({
            method: 'put',
            url: HOST()+'/esklep/mobile/user/setNewPassword',
            data: {
                'login': atob(login),
                'newPassword': haslo,
                'token': token
            }
        })
    },

    ZmianaHasla(data: any) {
        return axios.request({
            method: 'put',
            url: HOST()+'/esklep/mobile/user/changePassword',
            // url: HOST()+`/klient/zmianaHasla`,
            data: data
        })
    },

    Zaloguj(login: string, pass: string) {
        return axios.request({
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            url: HOST()+`/esklep/mobile/user/login`,
            data: {
                "haslo": pass,
                "login": login,
                "numerImei": 'web',
                "kraj": 'CZ'
            }
        })
    },

    getKlient(email: string) {
        return axios.request({
            method: 'post',
            url: HOST()+`/esklep/mobile/user/getUserData`,
            // url: HOST()+`klient/${id}`
            data: {
                login: email
            }
        })

        // return axios.request({
        //     method: 'get',
        //     url: HOST()+`klient/${id}`
        // })
    },

    UsunKontoKlienta(data: any) {
        return axios.request({
            method: 'put',
            url: HOST()+'/esklep/mobile/user/prepareDeleteAccount',
            // url: HOST()+'klient/zmianaHasla',
            data: data
        })
    },

    getKarty() {
        return axios.request({
            method: 'get',
            url: HOST()+`/api/klient/karta/aktywne`
        })
    },

    getOddzialy() {
        return axios.request({
            method: 'get',
            url: HOST()+`/api/klient/kasa/klientDaneKasy`
        })
    },

    getOddzialyRegister() {
        return axios.request({
            method: 'get',
            url: HOST()+'/esklep/mobile/oddzialy?kraj=CZ'
        })
    },

    getKrajeFirma() {
        return axios.request({
            method: 'get',
            url: HOST()+'/esklep/mobile/user/getCountries'
        })
    },

    putKlientSklep(id: number, kraj: string, numag: number) {
        return axios.request({
            method: 'put',
            url: HOST()+`/api/klient/kontrahent/${id}/sklep`,
            data: {
                'kraj': kraj,
                'numag': numag
            }
        })
    },

    aktualizujDane(data: any) {
        return axios.request({
            method: 'put',
            url: HOST()+`/esklep/mobile/user/setUserData`,
            // url: HOST()+`klient/kontrahent/${id}`,
            data: data
        })
    },

    getDaneDoMigracji(typ: string, login: string, pass: string) {
        return axios.request({
            method: 'post',
            url: HOST()+`/esklep/mobile/user/${typ}`,
            data: {
                "haslo": pass,
                "login": login,
                "numerImei": 'web',
                "kraj": 'CZ'
            }
        })
    },

    WeryfikujNip(kraj: string, nip: string) {
        return axios.request({
            method: 'get',
            url: HOST()+`/esklep/mobile/user/validateTaxId/${kraj}/${nip}`
        })
    },

    weryfikujLogin(login: any) {
        return axios.request({
            method: 'get',
            url: HOST()+`/esklep/mobile/user/checkLogin?login=${login}`
        })
    },

    ConfirmUsunKontoKlienta(token: any, login: any) {
        return axios.request({
            method: 'put',
            url: HOST()+`/esklep/mobile/user/confirmDeleteAccount?token=${token}&login=${atob(login)}`,
        })
    },

    getTrescHtml(typ: any, kraj: any) {
        return axios.request({
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            url: HOST()+`/esklep/mobile/cms/getWpisyCms`,
            data: {
                typ: typ,
                kraj: kraj
            }
        })
    },

}
