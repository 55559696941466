import * as React from 'react';
import card from '../../images/karta-mm.png'
import Account from "./Account";
import {getCurrentUser, kraj} from "../../utilities/Utilities";
import Services from "../../utilities/Services";
import {AplikacjeMobilne} from "../sharedComponents/AplikacjeMobilne";
import Lang from "../../utilities/Language";

interface Props {
}

export class Card extends React.Component<any> {

    state = {
        user: [],
        upust: '',
        upustObrot: '',
        rabatKwota: '',
        loading: false
    }

    componentDidMount() {
        this.pobierzKlient();
        this.setState({
            upust: getCurrentUser().upust,
            upustObrot: getCurrentUser().upustObrot,
            rabatKwota: getCurrentUser().rabatKwota
        });

        //alert(this.state.klient)
    }

    pobierzKlient = () => {
        this.setState({loading: true});
        //alert(axios.defaults.headers.common.Authorization)

        Services.getKlient(getCurrentUser().klientId)
            .then((response) => {
                if (response.status === 200) {

                    console.log("get Klient ok");

                    this.setState({user: response.data, loading: false});

                }
            }).catch((error) => {
            this.setState({loading: false});
            console.log('get Klient error');
        })
    }

    render() {
        let {upust,upustObrot,rabatKwota,user, loading} = this.state;
        return <section id={'card'}>
            <h3>{Lang[kraj].karta}</h3>
            {
                (loading)?
                    <h5>{Lang[kraj].ladowanie}...</h5>
                    :
                    //@ts-ignore
                    (user.nazwa)?
                        <>
                            <img src={card} className={'card-img'}/>
                            {(upust)? <div>{Lang[kraj].upustStaly}: <span>{upust}%</span></div> : null}
                            {/*<div>Upust stały: <span>{(upust)? {upust}+'%' : ''}</span></div>*/}
                            {(upustObrot)? <div>{Lang[kraj].ostatniUdzielony}: <span>{upustObrot}%</span></div> : null}
                            {/*<div>Ostatni udzielony upust od obrotu: <span>{upustObrot}%</span></div>*/}
                            {(rabatKwota)? <div>{Lang[kraj].kwotaRabatuNaZakup}: <span>{rabatKwota} {Lang[kraj].waluta}</span></div> : null}
                            {/*<div>Kwota rabatu na następny zakup: <span>{rabatKwota} PLN</span></div>*/}
                        </>
                        :
                        <>
                            <h5>{Lang[kraj].brakPrzypisanejKarty}</h5>
                            {/*@ts-ignore*/}
                            <p>{Lang[kraj].brakKartyInfo}<br/> {Lang[kraj].brakKartyInfo2}: <b>{user.email}</b></p>
                            <AplikacjeMobilne wersjaPelna={false}/>
                        </>
            }

        </section>
    }

}

export default Card;

