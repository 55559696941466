import * as React from 'react';
import {Main} from "../components/Main";
import {Box} from "../components/sharedComponents/Box";
import {useEffect, useState} from "react";
import Services from "../utilities/Services";
import {isEmpty, kraj} from "../utilities/Utilities";
import Lang from "../utilities/Language";

interface Props {
}

export const Statute = (props: Props) => {
    const [regulamin, setRegulamin]: any = useState();

    useEffect(() => {
        pobierzRegulamin();
    }, [])

    const pobierzRegulamin = React.useCallback(() => {
        Services.getTrescHtml('REGULAMIN', 'CZ').then((response) => {
            setRegulamin(response.data);
        }).catch((e) => {
            NotificationManager.error('', 'Error');
        })
    }, [])

    return <Main active={'STATUTE'}>

        <div className={'statute'}>
            {(isEmpty(regulamin))?
                <h5>{Lang[kraj].ladowanie}...</h5>
                :
                regulamin.map((reg: any) =>
                    <details>
                        <summary>{reg.tytul}</summary>
                        <Box style={{margin: '10px'}}><div dangerouslySetInnerHTML={{__html: reg.tresc}} style={{height: '100%'}}/></Box>
                    </details>
                )}
        </div>

    </Main>
}

Statute.defaultProps = {}

