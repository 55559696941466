import * as React from 'react';
import googlePlay from '../img/icons/google-play-badge.png';
import qrAndroid from '../img/icons/qr_app_android.png';
import Lang from '../../utilities/Language';
import {kraj} from '../../utilities/Utilities';

interface Props {
    wersjaPelna: boolean
}

export const AplikacjeMobilne = (props: Props) => {

    return (
        <>
            {(props.wersjaPelna)?
                // <div className={'container'}>
                    <div id={'main-container'} style={{marginTop: '0', marginBottom: '100px'}}>
                        <div className="row">
                            <div className="col-sm-4">
                                <p>
                                    <h5>{Lang[kraj].pobierzAplikacjeMobilna}</h5>
                                </p>
                                <p>{Lang[kraj].pobierzAplikacjeMobilnaIMiej}</p>
                                <i>
                                    {Lang[kraj].aplikacjaDost} <a href='https://play.google.com/store/apps/details?id=topsa.merkurymarket.ekarta'> GooglePlay</a>
                                </i>
                                <p>
                                    <a href='https://play.google.com/store/apps/details?id=topsa.merkurymarket.ekarta'><img style={{width: 200}} alt='pobierz z Google Play' src={googlePlay}/></a>
                                </p>
                            </div>
                            <div className="col-sm-4">
                                <img style={{width: 200}} alt='pobierz z Google Play' src={qrAndroid}/>
                            </div>
                        </div>
                    </div>
                // </div>
            :
                <div id={'main-container'} style={{marginTop: '30px', marginBottom: '50px'}}>
                    <div className="row">
                        <div className="col-sm-5">
                            <p>
                                <h5>{Lang[kraj].pobierzAplikacjeMobilna}</h5>
                            </p>
                            <p>{Lang[kraj].pobierzAplikacjeMobilnaIMiej}</p>
                            <i>
                                {Lang[kraj].aplikacjaDost} <a href='https://play.google.com/store/apps/details?id=topsa.merkurymarket.ekarta'>GooglePlay</a>
                            </i>
                            <p>
                                <a href='https://play.google.com/store/apps/details?id=topsa.merkurymarket.ekarta'><img style={{width: 200}} alt='pobierz z Google Play' src={googlePlay}/></a>
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <img style={{width: 200}} alt='pobierz z Google Play' src={qrAndroid}/>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

AplikacjeMobilne.defaultProps = {wersjaPelna: true}

