import * as React from 'react';
import {Main} from "../components/Main";
import {CheckBox} from "../components/sharedComponents/Forms";
import Services from "../utilities/Services";
import {isEmpty, kraj} from "../utilities/Utilities";
import {Box} from "../components/sharedComponents/Box";
import {NotificationManager} from "react-notifications";
import Lang from "../utilities/Language";
import InputMask from "react-input-mask";
import Req from "../components/Req";
import axios from "axios";

interface MatchParams {
    path: string;
}

export interface RouteComponentProps<MatchParams> {
    match: any;
    history: any;
}

interface State {

    isCompany: boolean,
    setCompany: boolean,
    isRegister: boolean,
    migracja: boolean,
    repeatPass: any,
    inputValues: any,
    oddzialy: any,
    kraje: any,
    loading: boolean,
    rejestracja: boolean,
    nipInvalid: any,
    nipMessage: string,
    migrationRest: any,
    loginError: boolean
    loginErrorMessage: any,
    loadingWeryfikacja: boolean
}

interface Props extends RouteComponentProps<MatchParams> {
}

export class Register extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            isCompany: false,
            setCompany: false,
            isRegister: false,
            nipInvalid: false,
            nipMessage: '',
            migracja: false,
            repeatPass: '',
            oddzialy: [],
            loginError: false,
            loginErrorMessage: false,
            loadingWeryfikacja: false,
            kraje: [],
            loading: false,
            rejestracja: false,
            migrationRest: [],
            inputValues:
                {
                    "login": "",
                    "konto_zablokowane": 0,
                    "nazwa_top": "",
                    "numer_magazynu": '',
                    "kraj": "CZ",
                    "kraj_firma": "CZ",
                    "zgoda_regulamin": 0,
                    "zgoda_wiek16": 0,
                    "zgoda_newsletter": 0,
                    "zgoda_karta": 0,
                    "zgoda_push": 0,
                    "pole_imie": "",
                    "pole_nazwisko": "",
                    "pole_firma": "",
                    "pole_firma_kontynuacja": "",
                    "pole_ulica": "",
                    "pole_nr_domu": "",
                    "pole_nr_mieszkania": "",
                    "pole_kod_pocztowy": "",
                    "pole_miejscowosc": "",
                    "pole_telefon": "",
                    "pole_nip": "",
                    "czy_firma": false,
                    "top_kod_sprzedawcy": '',
                    "password": "",
                    "email": "",
                    "id": '',
                    "ico": ""
                }
        }

    }

    componentDidMount() {
        let {match} = this.props;

        if (match)
            if (match.params)
                if (match.params.migracja)
                {
                    if(match.params.migracja === 'migracja'){
                        if(sessionStorage.getItem('migrationData')){
                            this.setState({migracja: true});
                            this.przygotujDaneDoMigracji(JSON.parse(String(sessionStorage.getItem('migrationData'))))
                        }
                    }
                }

        this.pobierzOddzialy();
        this.pobierzKrajeFirma();
    }

    przygotujDaneDoMigracji = (migrationData: any) => {
        console.log('DANE do migracji', migrationData);
        let data = {
            "login": this.returnValue(migrationData.login, ''),
            "konto_zablokowane": 0,
            "nazwa_top": this.returnValue(migrationData.nazwa_top, ''),
            "numer_magazynu": (isEmpty(migrationData.numer_magazynu)) ? '' : migrationData.numer_magazynu,
            "kraj": (migrationData.typMigracji === 'migration/editoTop')? 'CZ' : this.returnValue(migrationData.kraj, 'CZ'),
            "kraj_firma": this.returnValue(migrationData.kraj_firma, 'CZ'),
            "zgoda_regulamin": 0,
            "zgoda_wiek16": migrationData.zgoda_wiek16,
            "zgoda_newsletter": migrationData.zgoda_newsletter,
            "zgoda_karta": migrationData.zgoda_karta,
            "zgoda_push": migrationData.zgoda_push,
            "pole_imie": this.returnValue(migrationData.pole_imie, ''),
            "pole_nazwisko": this.returnValue(migrationData.pole_nazwisko, ''),
            "pole_firma": this.returnValue(migrationData.pole_firma, ''),
            "pole_firma_kontynuacja": this.returnValue(migrationData.pole_firma_kontynuacja, ''),
            "pole_ulica": this.returnValue(migrationData.pole_ulica, ''),
            "pole_nr_domu": this.returnValue(migrationData.pole_nr_domu, ''),
            "pole_nr_mieszkania": this.returnValue(migrationData.pole_nr_mieszkania, ''),
            "pole_kod_pocztowy": this.returnValue(migrationData.pole_kod_pocztowy, ''),
            "pole_miejscowosc": this.returnValue(migrationData.pole_miejscowosc, ''),
            "pole_telefon": this.returnValue(migrationData.pole_telefon, ''),
            "pole_nip": this.returnValue(migrationData.pole_nip, ''),
            "czy_firma": (this.returnValue(migrationData.pole_nip, '').length > 0),
            "top_kod_sprzedawcy": '',
            "password": this.returnValue(migrationData.haslo, ''),
            "email": this.returnValue(migrationData.login, ''),
            "id": this.returnValue(migrationData.id, '0'),
            "ico": this.returnValue(migrationData.ico, '')
        }

        if (data.czy_firma){
            this.setState({isCompany: true});
            data.pole_imie='';
            data.pole_nazwisko='';


        } else {
            this.setState({isCompany: false});
            data.pole_firma='';
            data.pole_firma_kontynuacja='';
            data.pole_nip='';

        }

        this.setState({inputValues: data, repeatPass: '', migrationRest: migrationData});
        sessionStorage.removeItem('migrationData');
    }

    returnValue = (val: any, defVal: string) => {
        if (isEmpty(val)){
            return defVal;
        } else {
            return String(val);
        }

    };

    pobierzKrajeFirma = () => {
        this.setState({loading: true});

        Services.getKrajeFirma().then((response) => {

            console.log("get Kraje ok");

            this.setState({kraje: response.data});

        }).catch((error) => {
                this.setState({loading: false});
                console.log('get Kraje error');
            }
        )
    }

    handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
        const {name, value}: any = e.target;
        let input = this.state.inputValues;

        if(name === 'login'){
            input[name] = value.toLowerCase();
        } else {
            input[name] = value;
        }

        this.setState({inputValues: input});
    };

    handleOnChecked = (e: React.FormEvent<HTMLInputElement>) => {
        const {name, checked}: any = e.target;
        let input = this.state.inputValues;
        input[name] = checked? 1 : 0;
        this.setState({inputValues: input});
    };

    handleOnChangerepeatPass = (e: React.FormEvent<HTMLInputElement>) => {
        const {value}: any = e.target;
        this.setState({repeatPass: value});
    }

    pobierzOddzialy = () => {
        this.setState({loading: true});

        Services.getOddzialyRegister().then((response) => {

            console.log("get Oddzialy ok");

            this.setState({oddzialy: response.data, loading: false});

            // this.setState({oddzialy: response.data.sort(
            //         (a: any, b: any) => {
            //             return a.adres.localeCompare(
            //                 b.adres,
            //             );
            //         },
            //     ).map((oddzial: any) => {return oddzial}), loading: false});

        }).catch((error) => {
                this.setState({loading: false});
                console.log('get Oddzialy error');
            }
        )
    }

    onChangeSklep = (e: any) => {
        //@ts-ignore
        console.log(e.target.value);

        let input = this.state.inputValues;
        input['numer_magazynu']=e.target.value;
        this.setState({inputValues: input});
    }

    // submitForm = (e: React.FormEvent<any>) => {
    //     e.preventDefault()
    //     let input = this.state.inputValues;
    //
    //     if (!this.state.isCompany)
    //     {
    //         input['topNazwa']=input['firma'].slice(0, 20);
    //         this.setState({inputValues: input});
    //     } else {
    //         if (!this.CountryCodeList.includes(input['nrIdent'].slice(0,2)))
    //         {
    //             NotificationManager.error('DIČ musí na začiatku obsahovať dvojmiestny kód krajiny, napríklad SK / CZ / PL', Lang[kraj].bladRejestracji, 5000);
    //             return 0;
    //         }
    //     }
    //
    //     const data = this.state.inputValues;
    //
    //     if(data.haslo===this.state.repeatPass)
    //     {
    //         this.setState({rejestracja: true});
    //
    //         Services.SprawdzHaslo(data.haslo).then((response) => {
    //             if (response.status === 200){
    //                 this.Rejestruj(data);
    //             }
    //         }).catch((error) => {
    //             NotificationManager.error(error.response.data.message, Lang[kraj].bladRejestracji, 5000);
    //             this.setState({rejestracja: false});
    //         })
    //
    //     }
    //     else
    //     {
    //         NotificationManager.error(Lang[kraj].rozneHasla, Lang[kraj].bladRejestracji, 5000);
    //     }
    //
    // }

    submitForm = (e: React.FormEvent<any>) => {
        e.preventDefault()

        // if (!this.state.isCompany)
        // {
        //     let input = this.state.inputValues;
        //     input['topNazwa']=input['firma'].slice(0, 20);
        //     this.setState({inputValues: input});
        // }

        const data = this.state.inputValues;

        data['email'] = data.login;
        data['czy_firma'] = this.state.isCompany? 1 : 0;
        data['numer_magazynu'] = Number(data['numer_magazynu']);
        // data['pole_kod_pocztowy'] = data.pole_kod_pocztowy.replace('_','');

        if(this.state.migracja){
            this.setState({rejestracja: true});
            this.Migruj(data);
        } else {
            if(data.password === this.state.repeatPass)
            {
                this.setState({rejestracja: true});

                Services.SprawdzHaslo(data.password).then((response) => {
                    if (response.status === 200){
                        this.Rejestruj(data);
                    }
                }).catch((error) => {
                    NotificationManager.error(error.response.data.message, Lang[kraj].bladRejestracji, 5000);
                    this.setState({rejestracja: false});
                })

            }
            else
            {
                NotificationManager.error(Lang[kraj].rozneHasla, Lang[kraj].bladRejestracji, 5000);
            }
        }
    }

    Migruj = (data: any) => {
        let login = this.state.migrationRest.login;
        let haslo = this.state.migrationRest.haslo;
        let typMi = this.state.migrationRest.typMigracji;

        Services.Migracja(data, typMi).then((response) => {

            Services.Zaloguj(login, haslo).then((response) => {

                let token = 'Basic ' + btoa(login + ':' + haslo)

                axios.defaults.headers.common.Authorization = token;

                sessionStorage.setItem('isUserLoggedMM',
                    JSON.stringify(Object.assign({}, response.data, {token: token}))
                )

                window.location.replace('/konto');

            }).catch(error => {
                NotificationManager.error(error.response.data.message, Lang[kraj].bladLogowania, 5000);
                window.location.replace('/prihlasenie');
            })

        }).catch((error) => {

            NotificationManager.error(error.response.data.message, Lang[kraj].bladAktualizacjiDanych, 5000);
            this.setState({rejestracja: false});

        })
    }

    Rejestruj = (data: any) => {

        console.log('Rejestruj', data);

        Services.Register(data)
            .then((response) => {
                if (response.status === 200) {

                    this.setState({isRegister: true, rejestracja: false});

                }
            }).catch((error) => {

            NotificationManager.error(error.response.data.message, Lang[kraj].bladRejestracji, 5000);
            this.setState({rejestracja: false});

        })

    }

    checkNip = () => {
        if(this.state.inputValues.pole_nip.length > 0){
            Services.WeryfikujNip(this.state.inputValues.kraj_firma, this.state.inputValues.pole_nip).then((response) => {
                this.setState({nipMessage: response.data.message});
                if(response.data.countryValid === false && response.data.euValid === false){
                    this.setState({nipInvalid: true});
                } else {
                    this.setState({nipInvalid: false});
                }
            }).catch((e) => {
                this.setState({nipInvalid: true, nipMessage: e.response.data.message});
            })
        } else {
            this.setState({nipInvalid: true, nipMessage: Lang[kraj].podajNip});
        }

        // if (validateNip(this.state.inputValues.pole_nip)) {
        //     this.setState({nipInvalid: false});
        // } else {
        //     this.setState({nipInvalid: true})
        // }
    }

    onChangeKrajFirma = (e: any) => {
        //@ts-ignore
        console.log(e.target.value);

        let input = this.state.inputValues;
        input['kraj_firma']=e.target.value;
        this.setState({inputValues: input});

        if(this.state.inputValues.pole_nip.length > 0){
            this.checkNip();
        } else {
            this.setState({nipInvalid: true, nipMessage: 'Podaj NIP'});
        }

    }
    czyscPola() {

        if(!this.state.migracja){
            this.setState({inputValues:
                {
                    "login": "",
                    "konto_zablokowane": 0,
                    "nazwa_top": "",
                    "numer_magazynu": '',
                    "kraj": "CZ",
                    "kraj_firma": "CZ",
                    "zgoda_regulamin": 0,
                    "zgoda_wiek16": 0,
                    "zgoda_newsletter": 0,
                    "zgoda_karta": 0,
                    "zgoda_push": 0,
                    "pole_imie": "",
                    "pole_nazwisko": "",
                    "pole_firma": "",
                    "pole_firma_kontynuacja": "",
                    "pole_ulica": "",
                    "pole_nr_domu": "",
                    "pole_nr_mieszkania": "",
                    "pole_kod_pocztowy": "",
                    "pole_miejscowosc": "",
                    "pole_telefon": "",
                    "pole_nip": "",
                    "czy_firma": this.state.isCompany,
                    "top_kod_sprzedawcy": '',
                    "password": "",
                    "email": "",
                    "id": '',
                    "ico": ""
                }, repeatPass: '', nipInvalid: false, nipMessage: '', loginErrorMessage: false}
            )
        }
    }

    onChangeNip = (text: any) => {
        let input = this.state.inputValues;
        input['nrIdent']=text.toUpperCase();
        this.setState({inputValues: input});
    }

    sprawdzLogin = () => {
        if(!this.state.migracja){
            this.setState({loadingWeryfikacja: true});
            Services.weryfikujLogin(this.state.inputValues.login).then((response) => {
                this.setState({loginError: false, loadingWeryfikacja: false, loginErrorMessage: true});
            }).catch((e) => {
                this.setState({loginError: true, loginErrorMessage: e.response.data.message, loadingWeryfikacja: false});
            })
        }
    }

    render()
    {

        let {inputValues, isCompany, isRegister, repeatPass, oddzialy, rejestracja, migracja, loadingWeryfikacja, loginError, loginErrorMessage, kraje, nipMessage, nipInvalid} = this.state;
        return(
            (!isRegister)?
                <Main active={'REGISTER'}>
                    <Box>
                    <h3>{(migracja)? Lang[kraj].aktualizujDane : Lang[kraj].rejestracja}</h3>
                    {(migracja)?
                        <p>{Lang[kraj].uzupelnijBrakujaceInformacjeOKoncie}.</p>
                        :
                        <p>{Lang[kraj].rejInfo}</p>
                    }
                    <form onSubmit={this.submitForm.bind(this)}>
                        <section className={'register-form'}>
                            <div className={'row'}>
                                <div className={'col-sm-3'}>
                                    <span>{Lang[kraj].login} ({Lang[kraj].email}):<Req/></span>
                                </div>
                                <div className={'col-sm-9'}>
                                    <input required type={'email'} className={'form-control'} value={inputValues.login} autoFocus={true}
                                           onChange={this.handleOnChange} disabled={migracja} onBlur={() => this.sprawdzLogin()} autoComplete="off"
                                           name={'login'}/>
                                    {/*{(loginError)&&*/}
                                    {/*    <p>{loginErrorMessage}</p>*/}
                                    {/*}*/}
                                    {(loadingWeryfikacja)?
                                        <p><i className="fa fa-spinner fa-spin"/> {Lang[kraj].weryfikacja}...</p>
                                        :
                                        <p style={{color: loginError? 'red' : 'green', display: 'block'}}>{(loginError)? <div>{loginErrorMessage} <a href={'/prihlasenie'}>{Lang[kraj].zalogujSie} <i className="fa fa-hand-pointer-o"/></a></div> : (loginErrorMessage === true)&&<div><i className="fa fa-check" style={{color: 'green'}}/> Login OK</div>}</p>
                                    }
                                </div>

                                {(!migracja)&&<>
                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].haslo}:<Req/></span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        <input required type={'password'} className={'form-control'} value={inputValues.password}
                                            // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,}"
                                               title={Lang[kraj].hasloTitle}
                                               onChange={this.handleOnChange}
                                               name={'password'}/>
                                    </div>
                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].powtorzHaslo}:<Req/></span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        <input required type={'password'} className={'form-control'}
                                            // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,}"
                                               title={Lang[kraj].hasloTitle}
                                               value={repeatPass}
                                               onChange={this.handleOnChangerepeatPass}/>
                                    </div>
                                </>
                                }

                                {(migracja)?
                                    <>
                                        <div className={'col-sm-3'}>
                                            <span>{Lang[kraj].typKonta}:</span>
                                        </div>
                                        <div className={'col-sm-9'} style={{lineHeight: '38px'}}>
                                            {(isCompany)? Lang[kraj].firma : Lang[kraj].osobaFizyczna}
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className={'col-sm-3'}>
                                            <span>{Lang[kraj].typKonta}:</span>
                                        </div>
                                        <div className={'col-sm-9'}>
                                            <div className={'radio-line'}>
                                                <input type={'radio'} checked={!isCompany} onChange={() => {this.setState({isCompany: !isCompany}); this.czyscPola();}}
                                                       name={'companyFalse'} id={"person"} disabled={migracja}/> <label htmlFor="person">{Lang[kraj].osobaFizyczna}</label>
                                                <input type={'radio'} checked={isCompany} onChange={() => {this.setState({isCompany: !isCompany}); this.czyscPola();}}
                                                       name={'companyTrue'} id={"company"} disabled={migracja}/> <label htmlFor="company">{Lang[kraj].firma}</label>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            {isCompany ?
                                <div className={'row'}>
                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].nazwaKrotka}:<Req/></span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        <input type={'text'} className={'form-control'}
                                               value={inputValues.nazwa_top}
                                               disabled={migracja}
                                               maxLength={20}
                                               required
                                               placeholder={Lang[kraj].maksymalnieZnakow}
                                               onChange={this.handleOnChange}
                                               name={'nazwa_top'}/>
                                    </div>
                                    <div className={'col-sm-3'} style={{marginBottom: '15px'}}>
                                        <span>{Lang[kraj].kraj}:<Req/></span>
                                    </div>
                                    <div className={'col-sm-9'} style={{marginBottom: '15px'}}>
                                        <select className={'form-control'}
                                                placeholder={'Wybierz...'}
                                                onChange={this.onChangeKrajFirma}
                                                required={true}
                                                value={inputValues.kraj_firma}
                                            //@ts-ignore
                                            //     value={((user.numag) !== 0) ? user.numag : null}
                                        >
                                            <option disabled selected>{Lang[kraj].wybierzKraj}...</option>
                                            {
                                                kraje.map((kraj: any) => (
                                                    <option value={kraj.value}>{kraj.label}</option>
                                                ))
                                            }
                                        </select>

                                    </div>
                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].nipCz}:<Req/></span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        {/*<input required type={'text'} pattern="[0-9]*" className={'form-control'} value={inputValues.nrIdent}*/}
                                        {/*       onChange={this.handleOnChange}*/}
                                        {/*       name={'nrIdent'}/>*/}

                                        {/*<InputMask mask={'aa9999999999'} className={'form-control'} style={{marginBottom: '10px'}}*/}
                                        {/*           maskPlaceholder={''}*/}
                                        {/*           title={'DIČ musí obsahovat kód země CZ/SK/PL'}*/}
                                        {/*           required*/}
                                        {/*           placeholder={'napríklad CZ0000000000'}*/}
                                        {/*           value={inputValues.nrIdent}*/}
                                        {/*           onChange={(e) => this.onChangeNip(e.target.value)}*/}
                                        {/*           name={'nrIdent'}*/}
                                        {/*/>*/}

                                        <span style={{color: nipInvalid? 'red' : 'green', display: 'block'}}>{nipMessage}</span>
                                        <input required type={'text'} className={'form-control'} value={inputValues.pole_nip}
                                               // pattern="[0-9]*"
                                               onBlur={() => this.checkNip()}
                                               onChange={this.handleOnChange}
                                               minLength={3}
                                               maxLength={15}
                                               disabled={migracja}
                                               name={'pole_nip'}/>
                                    </div>
                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].nip}:<Req/></span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        {/*<input required type={'text'} pattern="[0-9]*" className={'form-control'} value={inputValues.nrIdent}*/}
                                        {/*       onChange={this.handleOnChange}*/}
                                        {/*       name={'nrIdent'}/>*/}

                                        {/*<InputMask mask={'aa9999999999'} className={'form-control'} style={{marginBottom: '10px'}}*/}
                                        {/*           maskPlaceholder={''}*/}
                                        {/*           title={'DIČ musí obsahovat kód země CZ/SK/PL'}*/}
                                        {/*           required*/}
                                        {/*           placeholder={'napríklad CZ0000000000'}*/}
                                        {/*           value={inputValues.nrIdent}*/}
                                        {/*           onChange={(e) => this.onChangeNip(e.target.value)}*/}
                                        {/*           name={'nrIdent'}*/}
                                        {/*/>*/}

                                        {/*<span style={{color: nipInvalid? 'red' : 'green', display: 'block'}}>{nipMessage}</span>*/}
                                        <input required type={'text'} className={'form-control'} value={inputValues.ico}
                                               // pattern="[0-9]*"
                                               // onBlur={() => this.checkNip()}
                                               onChange={this.handleOnChange}
                                               minLength={3}
                                               maxLength={15}
                                               disabled={migracja}
                                               name={'ico'}/>
                                    </div>
                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].firma}:<Req/></span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        <input required type={'text'} className={'form-control'} value={inputValues.pole_firma}
                                               maxLength={90}
                                               onChange={this.handleOnChange}
                                               disabled={migracja}
                                               name={'pole_firma'}/>
                                    </div>
                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].firmaCd}:</span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        <input type={'text'} className={'form-control'}
                                               value={inputValues.pole_firma_kontynuacja}
                                               disabled={migracja}
                                               maxLength={90}
                                               onChange={this.handleOnChange}
                                               name={'pole_firma_kontynuacja'}/>
                                    </div>
                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].telefon}:<Req/></span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        <input required type={'text'} className={'form-control'}
                                               value={inputValues.pole_telefon}
                                               onChange={this.handleOnChange}
                                               name={'pole_telefon'}/>
                                    </div>
                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].kodPocztowy}:<Req/></span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        <InputMask mask={''} className={'form-control'} style={{maxWidth: '120px', marginBottom: '10px'}}
                                                   value={inputValues.pole_kod_pocztowy}
                                                   maxLength={9}
                                                   onChange={this.handleOnChange}
                                                   name={'pole_kod_pocztowy'}
                                                   required
                                        />
                                    </div>
                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].miasto}:</span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        <input required type={'text'} className={'form-control'} value={inputValues.pole_miejscowosc}
                                               onChange={this.handleOnChange}
                                               maxLength={50}
                                               name={'pole_miejscowosc'}/>
                                    </div>
                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].nazwaUlicy}:</span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        <input type={'text'} className={'form-control'}
                                               value={inputValues.pole_ulica}
                                               onChange={this.handleOnChange}
                                               maxLength={40}
                                               name={'pole_ulica'}/>
                                    </div>
                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].nrDomu}:<Req/></span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        <input required type={'text'} className={'form-control'} style={{maxWidth: '120px'}}
                                               value={inputValues.pole_nr_domu}
                                               onChange={this.handleOnChange}
                                               name={'pole_nr_domu'}/>
                                    </div>
                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].nrMieszkania}:</span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        <input type={'text'} className={'form-control'} style={{maxWidth: '120px'}}
                                               value={inputValues.pole_nr_mieszkania}
                                               onChange={this.handleOnChange}
                                               name={'pole_nr_mieszkania'}/>
                                    </div>
                                    {/*<div className={'col-sm-3'}>*/}
                                    {/*    <span>{Lang[kraj].adresCd}:</span>*/}
                                    {/*</div>*/}
                                    {/*<div className={'col-sm-9'}>*/}
                                    {/*    <input type={'text'} className={'form-control'}*/}
                                    {/*           value={inputValues.adres2}*/}
                                    {/*           onChange={this.handleOnChange}*/}
                                    {/*           name={'adres2'}/>*/}
                                    {/*</div>*/}

                                    <div className={'col-sm-3'} style={{marginBottom: '15px'}}>
                                        <span>{Lang[kraj].twojSklep}:</span>
                                    </div>
                                    <div className={'col-sm-9'} style={{marginBottom: '15px'}}>
                                        <select className={'form-control'}
                                                placeholder={'Wybierz...'}
                                                onChange={this.onChangeSklep}
                                                value={inputValues.numer_magazynu}
                                                required={true}
                                            //@ts-ignore
                                            //     value={((user.numag) !== 0) ? user.numag : null}
                                        >
                                            <option value="" disabled selected>{Lang[kraj].wybierzTwojSklep}...</option>
                                            {
                                                oddzialy.map((oddzial: any) => (
                                                    <option value={oddzial.numag}>{oddzial.adresPelen}</option>
                                                ))
                                            }
                                        </select>

                                    </div>
                                    <div className={'col-sm-11 my-auto'} style={{textAlign: 'right'}}>
                                        <span>{Lang[kraj].nrSprzedawcyPolecajacego}:</span>
                                    </div>
                                    <div className={'col-sm-1 my-auto'}>
                                        {/*<InputMask mask={'aa'} className={'form-control'} style={{maxWidth: '40px'}}*/}
                                        {/*           value={inputValues.kodSprzedawcy}*/}
                                        {/*           onChange={this.handleOnChange}*/}
                                        {/*           name={'kodSprzedawcy'}*/}
                                        {/*/>*/}
                                        <input type={'text'} className={'form-control'} style={{maxWidth: '120px'}}
                                               value={inputValues.top_kod_sprzedawcy}
                                               onChange={this.handleOnChange}
                                               name={'top_kod_sprzedawcy'}/>
                                    </div>

                                    <div className={'col-sm-12'} style={{marginBottom: '25px'}}>
                                    </div>

                                    <div className={'col-sm-2'}>
                                        <CheckBox required={true} name={'zgoda_regulamin'} checked={inputValues.zgoda_regulamin} handleOnChange={this.handleOnChecked}/>
                                    </div>
                                    <div className={'col-sm-10 mb-2'}>
                                        <span className={'checkbox-span'}><i>*</i> {Lang[kraj].zgodaRegulamin} </span>
                                    </div>
                                    <div className={'col-sm-2'}>
                                        <CheckBox required={false} name={'zgoda_newsletter'} checked={inputValues.zgoda_newsletter} handleOnChange={this.handleOnChecked}/>
                                    </div>
                                    <div className={'col-sm-10 mb-2'}>
                                        <span className={'checkbox-span'}>{Lang[kraj].zgodaNewsletter}</span>
                                    </div>
                                    <div className={'col-sm-2'}>
                                        <CheckBox required={false} name={'zgoda_push'} checked={inputValues.zgoda_push} handleOnChange={this.handleOnChecked}/>
                                    </div>
                                    <div className={'col-sm-10 mb-2'}>
                                        <span className={'checkbox-span'}>{Lang[kraj].zgodaPush}</span>
                                    </div>
                                </div> :
                                <div className={'row'}>
                                    {/*<div className={'col-sm-3'}>*/}
                                    {/*    <span>{Lang[kraj].imieNazwisko}:</span>*/}
                                    {/*</div>*/}
                                    {/*<div className={'col-sm-9'}>*/}
                                    {/*    <input required type={'text'} className={'form-control'}*/}
                                    {/*           value={inputValues.firma}*/}
                                    {/*           onChange={this.handleOnChange}*/}
                                    {/*           name={'firma'}/>*/}
                                    {/*</div>*/}

                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].imie}:<Req/></span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        <input required type={'text'} className={'form-control'}
                                               value={inputValues.pole_imie}
                                               onChange={this.handleOnChange}
                                               name={'pole_imie'}/>
                                    </div>
                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].nazwisko}:<Req/></span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        <input required type={'text'} className={'form-control'}
                                               value={inputValues.pole_nazwisko}
                                               onChange={this.handleOnChange}
                                               name={'pole_nazwisko'}/>
                                    </div>

                                    <div className={'col-sm-3'} style={{marginBottom: '15px'}}>
                                        <span>{Lang[kraj].kraj}:<Req/></span>
                                    </div>
                                    <div className={'col-sm-9'} style={{marginBottom: '15px'}}>
                                        <select className={'form-control'}
                                                placeholder={'Wybierz...'}
                                                onChange={this.onChangeKrajFirma}
                                                required={true}
                                                value={inputValues.kraj_firma}
                                            //@ts-ignore
                                            //     value={((user.numag) !== 0) ? user.numag : null}
                                        >
                                            <option disabled selected>{Lang[kraj].wybierzKraj}...</option>
                                            {
                                                kraje.map((kraj: any) => (
                                                    <option value={kraj.value}>{kraj.label}</option>
                                                ))
                                            }
                                        </select>

                                    </div>

                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].telefon}:<Req/></span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        <input required type={'text'} className={'form-control'}
                                               value={inputValues.pole_telefon}
                                               onChange={this.handleOnChange}
                                               name={'pole_telefon'}/>
                                    </div>

                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].kodPocztowy}:</span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        <InputMask mask={''} className={'form-control'} style={{maxWidth: '120px', marginBottom: '10px'}}
                                                   value={inputValues.pole_kod_pocztowy}
                                                   maxLength={9}
                                                   onChange={this.handleOnChange}
                                                   name={'pole_kod_pocztowy'}
                                                   required
                                        />
                                    </div>
                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].miasto}:</span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        <input required type={'text'} className={'form-control'} value={inputValues.pole_miejscowosc}
                                               onChange={this.handleOnChange}
                                               maxLength={50}
                                               name={'pole_miejscowosc'}/>
                                    </div>
                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].nazwaUlicy}:</span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        <input type={'text'} className={'form-control'}
                                               value={inputValues.pole_ulica}
                                               onChange={this.handleOnChange}
                                               maxLength={40}
                                               name={'pole_ulica'}/>
                                    </div>
                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].nrDomu}:<Req/></span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        <input required type={'text'} className={'form-control'} style={{maxWidth: '120px'}}
                                               value={inputValues.pole_nr_domu}
                                               onChange={this.handleOnChange}
                                               name={'pole_nr_domu'}/>
                                    </div>
                                    <div className={'col-sm-3'}>
                                        <span>{Lang[kraj].nrMieszkania}:</span>
                                    </div>
                                    <div className={'col-sm-9'}>
                                        <input type={'text'} className={'form-control'} style={{maxWidth: '120px'}}
                                               value={inputValues.pole_nr_mieszkania}
                                               onChange={this.handleOnChange}
                                               name={'pole_nr_mieszkania'}/>
                                    </div>

                                    <div className={'col-sm-3'} style={{marginBottom: '15px'}}>
                                        <span>{Lang[kraj].twojSklep}:</span>
                                    </div>
                                    <div className={'col-sm-9'} style={{marginBottom: '15px'}}>
                                        <select className={'form-control'}
                                                placeholder={'Wybierz...'}
                                                onChange={this.onChangeSklep}
                                                value={inputValues.numer_magazynu}
                                                required={true}
                                            //@ts-ignore
                                            //     value={((user.numag) !== 0) ? user.numag : null}
                                        >
                                            <option value="" disabled selected>{Lang[kraj].wybierzTwojSklep}...</option>
                                            {
                                                oddzialy.map((oddzial: any) => (
                                                    <option value={oddzial.numag}>{oddzial.adresPelen}</option>
                                                ))
                                            }
                                        </select>

                                    </div>
                                    <div className={'col-sm-11 my-auto'} style={{textAlign: 'right'}}>
                                        <span>{Lang[kraj].nrSprzedawcyPolecajacego}:</span>
                                    </div>
                                    <div className={'col-sm-1 my-auto'}>
                                        {/*<InputMask mask={'aa'} className={'form-control'} style={{maxWidth: '40px'}}*/}
                                        {/*           value={inputValues.kodSprzedawcy}*/}
                                        {/*           onChange={this.handleOnChange}*/}
                                        {/*           name={'kodSprzedawcy'}*/}
                                        {/*/>*/}
                                        <input type={'text'} className={'form-control'} style={{maxWidth: '120px'}}
                                               value={inputValues.top_kod_sprzedawcy}
                                               onChange={this.handleOnChange}
                                               name={'top_kod_sprzedawcy'}/>
                                    </div>

                                    <div className={'col-sm-12'} style={{marginBottom: '25px'}}>
                                    </div>

                                    <div className={'col-sm-2'}>
                                        <CheckBox required={true} name={'zgoda_regulamin'} checked={inputValues['zgoda_regulamin']} handleOnChange={this.handleOnChecked}/>
                                    </div>
                                    <div className={'col-sm-10 mb-2'}>
                                        <span className={'checkbox-span'}><i>*</i> {Lang[kraj].zgodaRegulamin}</span>
                                    </div>
                                    <div className={'col-sm-2'}>
                                        <CheckBox required={true} name={'zgoda_wiek16'} checked={inputValues['zgoda_wiek16']} handleOnChange={this.handleOnChecked}/>
                                    </div>
                                    <div className={'col-sm-10 mb-2'}>
                                <span
                                    className={'checkbox-span'}><i>*</i> {Lang[kraj].zgodaWiek}</span>
                                    </div>
                                    <div className={'col-sm-2'}>
                                        <CheckBox required={true} name={'zgoda_karta'} checked={inputValues['zgoda_karta']} handleOnChange={this.handleOnChecked}/>
                                    </div>
                                    <div className={'col-sm-10 mb-2'}>
                                        <span className={'checkbox-span'}><i>*</i> {Lang[kraj].zgodaKarta}</span>
                                    </div>
                                    <div className={'col-sm-2'}>
                                        <CheckBox required={false} name={'zgoda_newsletter'} checked={inputValues['zgoda_newsletter']} handleOnChange={this.handleOnChecked}/>
                                    </div>
                                    <div className={'col-sm-10 mb-2'}>
                                        <span className={'checkbox-span'}>{Lang[kraj].zgodaNewsletter}</span>
                                    </div>
                                    <div className={'col-sm-2'}>
                                        <CheckBox required={false} name={'zgoda_push'} checked={inputValues['zgoda_push']} handleOnChange={this.handleOnChecked}/>
                                    </div>
                                    <div className={'col-sm-10 mb-2'}>
                                        <span className={'checkbox-span'}>{Lang[kraj].zgodaPush}</span>
                                    </div>
                                </div>
                            }
                        </section>
                        <div className={'buttons-line'}>
                            <button className={'btn btn-custom-secondary'} disabled={rejestracja || loginError}>{(migracja)? ((rejestracja)? <><i className="fa fa-spinner fa-spin"/> {Lang[kraj].zapisywanie}...</> : Lang[kraj].zapisz) : ((rejestracja)? <><i className="fa fa-spinner fa-spin"/> {Lang[kraj].trwaRejestracja}...</> : Lang[kraj].zarejestruj)}</button>
                        </div>
                    </form>
                    <hr/>
                    <div>
                        <small>
                        Podle čl. 13 GDPR BM Česko s.r.o. se sídlem v Praze informuje, že:
                        1) správcem vašich osobních údajů je  BM Česko s.r.o. se sídlem v Praze (Türkova ul. 1272/7, 149 00 Praha 4 – Chodov),
                        2) vaše osobní údaje budou zpracovány pro účely servisu věrnostního programu Pravidelné Zákaznické karty - na základě článku. 6 s 1 písm. a) GDPR, v případě souhlasu bod 4 - vaše osobní údaje budou zpracovány za účelem přihlášení k odběru zpravodaje podle čl. 6 s 1 písm. a) GDPR, a v případě souhlasu s bodem 5 - budou vaše osobní údaje zpracovávány pro účely přímého marketingu podle čl. 6 s 1 písm. f) GDPR,
                        3) Vaše osobní údaje budou zpracovány až do data odvolání souhlasu se zpracováním osobních údajů udělené výše, nejdéle však: (i) v případě souhlasu se zpracováním osobních údajů pro účely související s implementací věrnostního programu Karty stálého zákazníka - po dobu účasti v programu a po uplynutí této doby v rozsahu vyžadovaném zákonem právo nebo zajistit jakékoli nároky, (ii) v případě souhlasu se zpracováním osobní údaje pro účely přihlášení k odběru newsletteru - nejdéle do data žádosti o odvolání souhlasu k odběru, (iii) v případě souhlasu s používáním koncových telekomunikačních zařízení - nejdéle, dokud nevznesete námitku proti zpracovávání,
                        4) máte právo požadovat od správce přístup k osobním údajům, právo na opravu, vymazání nebo omezení zpracování, právo vznést námitku proti zpracování, právo na přenos údajů, právo kdykoli odvolat souhlas,
                        5) máte právo podat stížnost u dozorového úřadu,
                        6) máte právo kontaktovat inspektora ochrany osobních údajů na adrese:  osobni.udaje@baumax.cz,
                        7) poskytnutí osobních údajů je podmínkou pro vydání věrnostní Karty nebo využívání této Karty, pokud je udělen souhlas v bodech 4 a 5 - poskytnutí osobních údajů je dobrovolné,
                        8) budou vaše údaje zpracovány automatizovaným způsobem, a to i formou profilování - platí pro přihlášení k odběru newsletteru.
                        </small>
                    </div>
                    </Box>
                </Main>
            :
                <Main active={'REGISTER'}>
                    <Box>
                        <h3 style={{color: 'green'}}><i className="fa fa-check" style={{color: 'green'}}/> {Lang[kraj].dziekujemyZaRejestracje} !</h3>
                        <p>{Lang[kraj].rejestracjaPozInfo}</p>
                    </Box>
                </Main>

        );
    }
}

export default Register;


